import React from 'react';
import { ToggleSwitchStyled, ToggleSwitchItemStyled } from './ToggleSwitch.styled';

function ToggleSwitch({ isToggled, onChange }: any) {
  return (
    <ToggleSwitchStyled>
      <input type="checkbox" checked={isToggled} onChange={onChange} />
      <ToggleSwitchItemStyled />
    </ToggleSwitchStyled>
  );
}

export default ToggleSwitch;
