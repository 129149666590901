import styled, { css, keyframes } from 'styled-components';
import { rem } from 'styles/theme';

const shimmer = keyframes`
    0% {
      transform: translate(100%);
    }
    40%, 100% {
    transform: translate(-150%);
    }
`

interface SkeletonStyledProps {
  width?: number;
  height?: number;
  radius?: number;
}

const sharedSkeletonMixin = css`
    overflow: hidden;
    position: relative;
    background-color: ${({ theme }) => theme.colors.secondary.light};
    
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
    90deg, 
    rgba(246, 246, 246, 0) 0, 
    rgba(246, 246, 246, 0.3) 20%, 
    rgba(246, 246, 246, 0.6) 60%, rgba(60, 246, 246, 0));
    animation: ${shimmer} 1s infinite;
  }
`

export const SkeletonStyled = styled.div<SkeletonStyledProps>`
    display: flex;
    height: ${({ height }) => height ? rem(height) : rem(16)};
    width: ${({ width }) => width ? rem(width) : '100%'};
    border-radius: ${rem(4)};
    ${sharedSkeletonMixin};
`;

export const SkeletonRowStyled = styled.div`
    display: flex;
    padding: 1rem 0;
    
    ${SkeletonStyled} {
      margin: 0 1rem;
    }
`;

const sharedSkeletonCardMixin = css`
    background-color: #fff;
    border-radius: 8px;
`;

export const SkeletonCardStyled = styled.div`
    ${sharedSkeletonCardMixin};
    padding: 1rem;
    display: flex;
    
    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
`;

export const SkeletonCardImageStyled = styled.div`
    width: ${rem(288)};
    height: ${rem(175)};
    display: flex;
    border-radius: ${rem(4)};
    ${sharedSkeletonMixin}
    
`;

export const SkeletonCardBodyStyled = styled.div`
   padding: ${rem(24)} ${rem(6)} 1rem ${rem(24)};
   flex-grow: 1;
   
   ${SkeletonStyled} {
    &:not(:last-of-type) {
      margin-bottom: ${rem(20)};
    }
   }
`;

export const SkeletonAvatarStyled = styled.div<SkeletonStyledProps>`
    width: ${({ width }) => width ? rem(width) : rem(64)};
    height: ${({ height }) => height ? rem(height) : rem(64)};
    display: flex;
    border-radius: ${({ radius }) => radius ? rem(radius) : '50%'};
    ${sharedSkeletonMixin}
`;

export const SkeletonFeedStyled = styled.div`
    ${sharedSkeletonCardMixin};
    padding: 1rem 1.5rem;
    
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
`;

export const SkeletonFeedHeadStyled = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

export const SkeletonFeedUserInfoStyled = styled.div`
    display: flex;
    align-items: center;
    
    ${SkeletonAvatarStyled} {
      margin-right: ${rem(12)};
    }
    
    ${SkeletonStyled} {
      &:first-of-type {
        margin-bottom: ${rem(8)};
      }
    }
`;

export const SkeletonFeedBodyStyled = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SkeletonFeedContentStyled = styled.div`
    padding: ${rem(24)} ${rem(70)} 0 ${rem(0)};
    flex-grow: 1;
   
    & > div:first-of-type {
      flex-grow: 1;
      
      & + div {
        margin-top: ${rem(18)};
      }
    }
    
    ${SkeletonStyled} {  
      margin-bottom: ${rem(8)};
    }
`;

export const SkeletonFeedDetailsStyled = styled.div`
    margin-top: 2rem;
    max-width: 20rem;
    display: flex;
    justify-content: space-between;
`;
