import React from 'react';
import { Layout, Sidebar, Main, Content, PreLoader, } from 'components';
import GlobalStyle from './styles/globalStyle';
import { ThemeProvider } from 'styled-components';
import { initialTheme } from './styles/theme';
import routes from './routes';
import { UsersProvider } from './context';
import { useAuth } from './hooks';
import { usePreLoader } from './hooks/usePreLoader';

export default function App() {
  const [isAuthorized] = useAuth();
  const [isPreLoading] = usePreLoader();

  return (
    <ThemeProvider theme={initialTheme}>
      <GlobalStyle />
      {isPreLoading ? <PreLoader /> :
        isAuthorized ?
          <Layout>
            <Sidebar />
            <Main>
              <UsersProvider>
                <Content>
                  {routes}
                </Content>
              </UsersProvider>
            </Main>
          </Layout>
          : <h1>Unauthorized</h1>
      }
    </ThemeProvider>
  );
}
