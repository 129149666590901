import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { rem } from 'styles/theme';

// language=SCSS prefix=*{ suffix=}
export const NavigationBarStyled = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
`;

export const NavigationBarListStyled = styled.ul`
    display: flex;
    align-items: center;
`;

export const NavigationBarItemStyled = styled.li``;

export const NavigationBarLinkStyled = styled(NavLink)`
    line-height: 1.18;
    color: ${({ theme }) => theme.colors.secondary.dark700};
    padding: 1rem;
    display: block;
    font-weight: 500;
    text-decoration: none;
    border-top-left-radius: ${rem(8)};
    border-top-right-radius: ${rem(8)};
    
    &.active {
      background-color: #fff;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.dark};
      pointer-events: none;
    }
`;
