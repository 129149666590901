import React from 'react';
import {
  NavigationStyled,
  NavigationMenuStyled,
  NavigationItemStyled,
  NavigationLinkStyled
} from './Navigation.styled';
import {
  ProfileIcon,
  CategoryIcon,
  StarIcon,
  ChartIcon,
  TicketIcon,
  SettingIcon
} from 'assets/icons';

const navLinks = [
  {
    path: '/users',
    name: 'Users',
    icon: <ProfileIcon />
  },
  {
    path: '/clubs-and-classes',
    name: 'Clubs and Classes',
    icon: <CategoryIcon />
  },
  {
    path: '/rewards',
    name: 'Rewards and Points',
    icon: <StarIcon />
  },
  {
    path: '/sponsored-ads',
    name: 'Sponsored Ads',
    icon: <ChartIcon />
  },
  {
    path: '/live-notis-feed',
    name: 'Live Notis Feed',
    icon: <TicketIcon />
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: <SettingIcon />
  }
]

export function Navigation() {
  return (
    <NavigationStyled>
      <NavigationMenuStyled>
        {navLinks.map(({ path, name, icon }) =>
          <NavigationItemStyled key={path}>
            <NavigationLinkStyled to={path}>{icon}{name}</NavigationLinkStyled>
          </NavigationItemStyled>
        )}
      </NavigationMenuStyled>
    </NavigationStyled>
  );
}
