import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';

const borderMixin = css`
    border-radius: ${rem(8)};
`;

const flexMixing = css`
    display: flex;
    justify-content: space-between;
`;

export const FeedStyled = styled.div`
    ${borderMixin};
    background-color: #fff;
    padding: 1rem ${rem(24)};
`;

export const FeedHeadStyled = styled.div`
    ${flexMixing};
    margin-bottom: ${rem(24)};
`;

export const FeedBodyStyled = styled.div`
    ${borderMixin};
    ${flexMixing};
    background-color: ${({ theme }) => theme.colors.tertiary.light};
`;

export const FeedFigureStyled = styled.figure`
    flex-shrink: 0;
`;

export const FeedImageStyled = styled.img`
    max-width: ${rem(288)};
    max-height: ${rem(175)};
    border-top-right-radius: ${rem(8)};
    border-bottom-right-radius: ${rem(8)};
`;

export const FeedContentStyled = styled.div`
    padding: 1rem ${rem(70)} ${rem(24)} 1rem;
    flex-grow: 1;
`;

export const FeedTitleStyled = styled.h3`
    font-size: ${rem(14)};
    margin-top: 0;
    color: #101828;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 1rem;
`;

export const FeedDescStyled = styled.p`
    font-size: ${rem(14)};
    line-height: 1.28;
    color: ${({ theme }) => theme.colors.secondary.darkish};
    margin-bottom: 1.5rem;
`;

export const FeedDetailsStyled = styled.div`
    display: flex;
    max-width: ${rem(520)};
`;

export const FeedDetailsColStyled = styled.div`
    &:first-of-type {
      width: 25%;
    }
    
    &:last-of-type {
      padding-left: 1.5rem;
      
      & > div:first-of-type {
        margin-bottom: ${rem(8)};
      }
    }
`;

export const FeedDetailsLabelStyled = styled.div`
   font-size: ${rem(14)};
   line-height: 1.2;
   color: ${({ theme }) => theme.colors.secondary.darkish};
`;

export const FeedDetailsInfoStyled = styled.div`
    font-size: ${rem(14)};
    color: #101828;
    line-height: 1.2;
`;
