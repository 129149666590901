import React from 'react';
import {
  NavigationBarStyled,
  NavigationBarListStyled,
  NavigationBarItemStyled,
  NavigationBarLinkStyled
} from './NavigationBar.styled';

interface Nav {
  path: string;
  name: string
}

interface NavigationBarProps {
  items: Nav[];
  children: React.ReactNode;
  onNavLinkHandler?: any
}

export function NavigationBar({ items, children, onNavLinkHandler }: NavigationBarProps) {
  return (
    <NavigationBarStyled>
      <NavigationBarListStyled>
        {items.map(({ path, name }) =>
          <NavigationBarItemStyled key={path}>
            <NavigationBarLinkStyled onClick={onNavLinkHandler} to={`/${path}`}>{name}</NavigationBarLinkStyled>
          </NavigationBarItemStyled>
        )}
      </NavigationBarListStyled>

      {children}
    </NavigationBarStyled>
  );
}

