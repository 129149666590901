import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';

// language=SCSS prefix=*{ suffix=}
const BgColor = css`
    background-color: #f9fafb;
`;

export const ModalStyled = styled.div`
    ${BgColor};
    z-index: 100;
    position: relative;
    margin: auto;
    border-radius: ${rem(8)};
    padding: ${rem(24)};
    min-width: ${rem(505)};
    max-width: ${rem(636)};
`;

export const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    width: inherit;
    outline: 0;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
   
    button {
      ${BgColor};
      box-shadow: none;
      padding: 0;
      width:${rem(32)};
      height:${rem(32)};
      position: relative;
      top: -${rem(14)};
      right: -${rem(14)};
   }
`;

export const HeaderTitle = styled.h2``;

export const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.secondary.dark700};
    opacity: 0.2;
    z-index: 500;
`;

export const Content = styled.div``;
