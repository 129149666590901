import styled, { css, keyframes } from 'styled-components';
import { rem } from 'styles/theme';

interface ButtonProps {
  size?: string;
  bold?: boolean;
  default?: boolean;
  primary?: boolean;
  secondary?: boolean;
  light?: boolean;
  loading?: string;
  tertiary?: boolean;
}

const spinner = keyframes`
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`;

const noClickMixin = css`
    pointer-events: none;
`;

const smallMediumMixin = css`
    font-size: ${rem(14)};
    line-height: 1.42;
`;

const spinnerMixin = css`
    transition: opacity 0.2s;
    ${noClickMixin};
    
    &:after {
      content: "";
      position: absolute;
      width: ${rem(18)};
      height: ${rem(18)};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 2px solid transparent;
      border-radius: 50%;
      animation: ${spinner} 0.8s ease infinite;
    }
    
    svg,
    span {
      visibility: hidden;
      opacity: 0;
    }
`;

export const CommonStyles = css<ButtonProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: ${rem(8)};
    box-shadow: 0 1px rgba(16, 24, 40, 0.05);
    background-color: transparent;
    cursor: pointer;
    text-align: center;
    border: none;
    font-weight: ${(props) => props.bold ? 500 : 400};
    position: relative;
    
    &:disabled {
      ${noClickMixin};
      background: ${({ theme }) => theme.colors.primary.light};
    }
    
    svg {
      margin-right: ${({ size }) => size ? rem(8) : null};
    }
    
    ${(props) => {
  switch (props.size) {
    case 'md':
      return css`
              padding: ${rem(10)} 1rem;
              ${smallMediumMixin};
            `;
    case 'sm':
      return css`
              padding: ${rem(8)} ${rem(14)};
              ${smallMediumMixin};
              height: ${rem(38)};
            `;
    case 'lg':
      return css`
              padding: ${rem(12)} ${rem(20)};
              font-size: 1rem;
              height: ${rem(48)};
              line-height: ${({ theme }) => theme.typography.body.lineHeight};
            `;
  }
}}
`;

const SecondaryStylesMixin = css`
    background:  #fff;
    color: ${({ theme }) => theme.colors.secondary.dark700};
    border: 1px solid #f9fafb;
`;

// language=SCSS prefix=*{ suffix=}
export const ButtonStyled = styled.button<ButtonProps>`
    ${CommonStyles};
    ${(props) => props.primary && css`
      color: #fff;
      background-color: ${props.light ? props.theme.colors.primary.lightDark : props.theme.colors.primary.dark};
      
      svg path {
        fill: #fff;
      }
    `};
    
    ${({ secondary }) => secondary && SecondaryStylesMixin};
    
    ${(props) => props.tertiary && css`
      background-color: ${({ theme }) => theme.colors.tertiary.light2};
      color: ${props.light ? props.theme.colors.primary.dark : props.theme.colors.secondary.darkish};
    `};
    
    ${(props) => props.loading && css`
       ${spinnerMixin};
      
       &::after {
         border-top-color:  ${props.primary ? '#fff' : props.theme.colors.primary.dark};
       }
    `}
`;

export const ButtonUploadStyled = styled.label.attrs<ButtonProps>({
  'name': 'upload-list',
})`
    ${CommonStyles};   
    ${SecondaryStylesMixin};  
     
     &:focus-within {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary.lightDark};
     }
     
     input {
       left: -1px;
       position: absolute;
       opacity: 0;
       z-index: -1;
     }
`;

export const ButtonIconStyled = styled.button`
    ${CommonStyles};
    position:relative;
    padding: ${rem(8)};  
    background: ${({ theme }) => theme.colors.tertiary.light};
    width: ${rem(40)};
    height: ${rem(40)};
    
     ${({ loading }) => loading && css`
       ${spinnerMixin};
      
       &::after {
         border-top-color: ${({ theme }) => theme.colors.primary.dark};
       }
    `}
`;

export const ButtonUpdateStyled = styled.button<ButtonProps>`
      ${CommonStyles};
      box-shadow: none;
      color: ${({ theme }) => theme.colors.primary.darkest};
`;
