import styled from 'styled-components';
import { rem } from 'styles/theme';

export const UserInfoStyled = styled.div`
    display: flex;
    align-items: center;
    
    & > div:first-of-type {
      margin-right: ${rem(19)};;
    }
`;

export const UserInfoBodyStyled = styled.div``;

export const UserInfoNameStyled = styled.div`
    margin-bottom: ${rem(4)};
    line-height: 1.2;
    color: #101828;
`;

export const UserInfoMetaStyled = styled.div`
    color: ${({ theme }) => theme.colors.secondary.darkish};
    font-size: ${rem(14)};
    line-height: 1.2;
    font-weight: 500;
`;
