import styled from 'styled-components';
import { rem } from 'styles/theme';

interface RewardPointNotifStyledProps {
  darkish?: boolean;
}

export const RewardPointNotifStyled = styled.div<RewardPointNotifStyledProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(14)};
    line-height: 1.74;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.secondary.darkish};
    
    svg {
      margin-right: ${rem(12)};
     
      path {
        fill: ${(props) => props.darkish ? props.theme.colors.secondary.lightDark : null};
      }
    }
`;
