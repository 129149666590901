import React from 'react';
import styled from 'styled-components';

interface TextMessageProps {
  message: string;
}

export const TextMessageStyled = styled.div`
    padding: 2rem;
    text-align: center;
    
    h2 {
      margin-bottom: 0;
    }
`;

export function TextMessage({ message }: TextMessageProps) {
  return (
    <TextMessageStyled>
      <h2>{message}</h2>
    </TextMessageStyled>
  );
}
