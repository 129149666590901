import styled from 'styled-components';
import { rem } from '../../styles/theme';

export const BreadcrumbsStyled = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 2rem;
    color: ${({ theme }) => theme.colors.secondary.lightDark};
    
    svg:first-child path {
      fill: ${({ theme }) => theme.colors.secondary.lightDark};
    }
    
    svg:nth-child(2) {
      margin: 0 ${rem(12)};
    }
    
    a {
      font-size: 0;
    }
`;

export const BreadcrumbsCurrentStyled = styled.span``;
