import React, { SetStateAction } from 'react';
import { LoadMoreStyled } from './LoadMore.styled';
import { Button } from '../Button';
import { useUsers } from 'context';

interface LoadMoreProps {
  setOffset: React.Dispatch<SetStateAction<number>>;
}

export function LoadMore({ setOffset }: LoadMoreProps) {
  const { setIsPending, isPaginating, setIsPaginating } = useUsers();

  const onLoadMoreHandler = () => {
    setIsPending(true);
    setIsPaginating(true);
    setOffset(prevState => prevState + 10);
  }

  return (
    <LoadMoreStyled>
      <Button
        loading={isPaginating}
        primary size={'lg'}
        onClick={onLoadMoreHandler}>
        <span>Load more</span>
      </Button>
    </LoadMoreStyled>
  );
}
