import styled from 'styled-components';
import { rem } from 'styles/theme';

interface Props {
  mb?: number;
}

export const PageHeaderStyled = styled.h1<Props>`
    margin-bottom: ${({ mb }) => mb ? rem(mb) : null};
    height: ${rem(40.8)};
`;
