import styled from 'styled-components';
import { SpinnerMixin } from 'styles/mixins'
import { rem } from 'styles/theme';

interface FeedListStyledProps {
  isPending: boolean;
}

export const FeedListWrapStyled = styled.div<FeedListStyledProps>`
    position: relative;
    ${({ isPending }) => isPending && SpinnerMixin}
`;

export const FeedListStyled = styled.ul`
    max-height: ${rem(640)};
    overflow-x: auto; 
`;

export const FeedItemStyled = styled.li`
    margin-bottom: 1rem;
`;
