import React from 'react';
import Select, { components } from 'react-select';
import { DropdownStyled } from './Dropdown.styled';
import { ArrowDownIcon } from 'assets/icons';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
};

export function Dropdown({ onChange, options, type }: any) {
  return (
    <DropdownStyled>
      <Select
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen
              ? 'rotate(180deg)' : 'rotate(0)',
            transition: '250ms',
          }),
          control: (base, state) => ({
            ...base,
            backgroundColor: state.selectProps.value
              ? type === 'roles' ? '#b2ddff' : '#f2f4f7' : '#f2f4f7',
            border: 0
          }),
        }}
        components={{ DropdownIndicator }}
        placeholder={'Select'}
        isSearchable={false}
        onChange={onChange}
        options={options} />
    </DropdownStyled>
  );
}
