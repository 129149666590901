import styled from 'styled-components';
import { rem } from 'styles/theme';
import { SpinnerMixin } from 'styles/mixins';

interface AdsCardsStyledProps {
  isPending: boolean;
}

export const AdsCardsStyled = styled.div<AdsCardsStyledProps>`
    ${({ isPending }) => isPending && SpinnerMixin};
`;

export const AdsCardsListStyled = styled.ul`
    max-height: ${rem(600)};
    overflow-x: hidden;
`;

export const AdsCardsListItemStyled = styled.li`
    margin-bottom: ${rem(24)};
`;
