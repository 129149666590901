import { DefaultTheme } from 'styled-components'

const fontSizeBase: number = 16;
export const rem = (sizeInPx: number): string => `${sizeInPx / fontSizeBase}rem`;

export const initialTheme: DefaultTheme = {
  colors: {
    primary: {
      light: '#b2ddff',
      lightest: '#eff8ff',
      light300: '#84caff',
      lightDark: '#53b1fd',
      dark: '#2e90fa',
      darkest: '#1570ef'
    },
    secondary: {
      light: '#e4e7ec',
      lightDark: '#98a2b3',
      regular: '#8e8e93',
      darkish: '#667085',
      darkMid: '#475467',
      dark: '#4d4d4e',
      dark700: '#344054',
      darkBlack: '#1d2939',
      darkest: '#262338'
    },
    tertiary: {
      lightest: '#fAfbfb',
      light: '#f2f4f7',
      light2: '#f4f4f4'
    },
    error: {
      dark: '#d92d20',
    }
  },
  typography: {
    h1: {
      size: rem(34),
      lineHeight: '1.2',
      letterSpacing: '1.5px',
    },
    h2: {
      size: rem(20),
      letterSpacing: '0.75px'
    },
    body: {
      fontSize: rem(16),
      fontFamily: 'Lato',
      lineHeight: '1.5'
    }
  }
};
