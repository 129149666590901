import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';
import { SpinnerMixin } from 'styles/mixins';

interface ContentStyledProps {
  isUploading: boolean;
}

// language=SCSS prefix=*{ suffix=}
export const ContentStyled = styled.div<ContentStyledProps>`
    padding: ${rem(75)} ${rem(24)} ${rem(75)} ${rem(32)};
    height: 100%;
    
    ${({ isUploading }) => isUploading && css`
      ${SpinnerMixin};
    `}
`;
