import React from 'react';
import { PageHeaderStyled } from './PageHeader.styled';

interface Props {
  title?: string | undefined;
  mb?: number
  titleChildren?: React.ReactNode;
}

export function PageHeader({ title, mb, titleChildren }: Props) {
  return (
    <>
      {title ? <PageHeaderStyled mb={mb}>{title}</PageHeaderStyled>
        : <PageHeaderStyled mb={mb}>{titleChildren}</PageHeaderStyled>}
    </>
  )
}
