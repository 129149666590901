import styled from 'styled-components';
import { rem } from 'styles/theme';
import { inputMixin } from 'components/Input';
import { DebounceInput } from 'react-debounce-input';

// language=SCSS prefix=*{ suffix=}
export const SearchStyled = styled.div`
    width: ${rem(272)};
    display: flex;
    align-items: center;
    position: relative;
    
    svg {
      position: absolute;
      left: ${rem(8)};
    }
`;

export const SearchFieldStyled = styled(DebounceInput)`
    ${inputMixin};
    background-color: #f9fafb;
    padding-top: ${rem(9)};
    color: #000;
    padding-left: ${rem(36)};
    border: 0;
    border-radius: 0;
    border-bottom: ${({ theme }) => theme.colors.secondary.light} 1px solid;
`;
