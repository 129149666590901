import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';

// language=SCSS prefix=*{ suffix=}
const flexMixin = css`
    display: inline-flex;
    align-items: center;
`;

export const RewardPointsUserInfoStyled = styled.div`
    display: flex;
    
    & > div:last-of-type {
      margin-left: ${rem(82)};
    }
`;

export const RewardPointsUserInfoItemStyled = styled.div`
    ${flexMixin};
`;

export const RewardPointsUserInfoLabelStyled = styled.div`
    ${flexMixin};
    margin-right: 2rem;
    color: ${({theme}) => theme.colors.secondary.darkBlack};
    
    svg {
      margin-right: ${rem(10)};
    }
`;
