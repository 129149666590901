export * from './date';

export const getLastUrlSegment = (path: string) => path.split('/').pop();

export const isUserModerator = (roles: string[]) => roles.includes('school_owner');

export const parseNumberInput = (value: string) => {
  return Math.abs(parseInt(value.replace(/,/g, ''), 10) || 0)
}

export const queryString = (query: string) => {
  const half = window.location.search.split(query + '=')[1];
  return half !== undefined ? decodeURIComponent(half.split('&')[0]) : '';
}

export const parseSessionStorage = (data: string | null) => {
  const dataToParse: any = data;
  return JSON.parse(dataToParse);
}
