import React from 'react';
import {
  InputGroup,
  InputLabel,
  Avatar,
  Skeleton,
  SkeletonAvatarStyled
} from 'components';

interface SchoolLogoProps {
  isLoading: boolean;
  name: string;
  imageUrl: string;
  onAvatarChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function SchoolLogo({
                             isLoading,
                             imageUrl,
                             name,
                             onAvatarChangeHandler
                           }: SchoolLogoProps) {
  return (
    <InputGroup nojustify>
      <InputLabel htmlFor="school_logo">
        {isLoading ?
          <>
            <Skeleton width={100} /> <br />
            <Skeleton width={200} />
          </>
          : <>
            School logo <br />
            <span>This will be displayed on your profile.</span>
          </>}
      </InputLabel>

      {isLoading ? <SkeletonAvatarStyled />
        : <label>
          <Avatar
            width={64}
            height={64}
            src={imageUrl}
            alt={name} />
          <input
            onChange={onAvatarChangeHandler}
            type='file'
            accept="image/png, image/gif, image/jpeg"
            id={'school_logo'}
            name={'school_logo'}
            hidden
          />
        </label>}
    </InputGroup>
  );
}
