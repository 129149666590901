import React, { useEffect, useState } from 'react';
import { API } from 'api';
import { PageHeader, SkeletonCard } from 'components';
import { AdsCards } from './components';
import { ISponsoredAds } from 'types';
import { CONST_FETCH_PAGE_ERROR } from 'utils/constants';

export function SponsoredAds() {
  const [adsData, setAdsData] = useState<ISponsoredAds[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [offset,] = useState(0);

  useEffect(() => {
    let isApiSubscribed = true;
    const fetchData = async () => {
      try {
        const { data } = await API.get(`/ads?limit=10&offset=${offset}`);
        if (isApiSubscribed) {
          setAdsData(adsData => [...adsData, ...data.results]);
        }
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    const timeout = setTimeout(() => {
      void fetchData();
    }, 600);

    return () => {
      clearTimeout(timeout);
      isApiSubscribed = false;
    };
  }, [offset]);

  if (error || adsData === undefined) {
    return <h1>{CONST_FETCH_PAGE_ERROR}</h1>
  }

  return (
    <>
      <PageHeader mb={72} title={'Sponsor Ads feature coming soon'} />

      {loading ? <SkeletonCard items={3} />
        : adsData && adsData.length ?
          <AdsCards
            setAdsData={setAdsData}
            adsCards={adsData}
          />
          : <h2>Ads list is empty.</h2>
      }
    </>
  );
}
