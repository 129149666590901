import React, { ReactNode } from 'react';
import { ContainerStyled } from './Container.styled';

interface Props {
  children: ReactNode;
}

export function Container({ children }: Props) {
  return <ContainerStyled>{children}</ContainerStyled>;
}
