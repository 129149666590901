import React, { useEffect, useState } from 'react';
import { API } from 'api';
import {
  Button,
  ButtonUpdate,
  PageControls,
  PageHeader,
  Container,
  NavigationBar,
  Input,
  InputText,
  Skeleton,
} from 'components';
import { SettingsStyled, SettingsRowStyled, SettingsItemStyled } from './Settings.styled';
import { CONST_FETCH_PAGE_ERROR } from 'utils/constants';
import { SchoolCode } from './components/SchoolCode';
import { SchoolLogo } from './components/SchoolLogo';
import { getSchoolLogo } from 'services';
import { ISchoolDetails } from 'types';

const categories = [
  { path: 'settings/school-details', name: 'School Details' },
];

const initialState = { id: '', name: '', images: [], url: '', address: '', parent_invite: { code: '' } }

export function Settings() {
  const [schoolDetails, setSchoolDetails] = useState<ISchoolDetails>(initialState);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isCodeGenerating, setIsCodeGenerating] = useState(false);
  const inputs = [
    {
      id: 'school_name',
      label: 'School name',
      type: 'text',
      defaultValue: schoolDetails.name
    },
    {
      id: 'website',
      label: 'Website',
      type: 'url',
      defaultValue: schoolDetails.url
    },
    {
      id: 'school_address',
      label: 'School address',
      type: 'text',
      defaultValue: schoolDetails.address
    }
  ]

  useEffect(() => {
    let isApiSubscribed = true;
    const fetchData = async () => {
      try {
        const { data } = await API.get('');
        if (isApiSubscribed) {
          setSchoolDetails(data);
          setAvatarUrl(getSchoolLogo(data));
        }
      } catch (e) {
        setError(true);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }

    const timeout = setTimeout(() => {
      void fetchData();
    }, 800);

    return () => {
      isApiSubscribed = false;
      clearTimeout(timeout);
    }
  }, []);

  const showAlertMessage = (message: string) => {
    setTimeout(() => alert(message), 100);
  };

  const onCreateSchoolCodeHandler = async () => {
    setIsCodeGenerating(true)
    try {
      const { data } = await API.post('/invites');
      setSchoolDetails(prevState => ({ ...prevState, parent_invite: { code: data.code } }));
      setIsCodeGenerating(false);
    } catch (e) {
      console.error(e);
      setIsCodeGenerating(false);
      showAlertMessage('Cannot create school code.');
    }
  }

  const onUpdateHandler = async () => {
    setIsPending(true);
    const formData = new FormData();
    formData.append('name', schoolDetails.name);
    formData.append('url', schoolDetails.url);
    formData.append('address', schoolDetails.address);
    if (avatarFile) {
      formData.append('image', avatarFile);
    }

    try {
      await API.put('', formData);
    } catch (e: any) {
      console.error(e);
      setIsPending(false);
      showAlertMessage('Fields cannot be empty and URL should be valid.')
    } finally {
      setIsPending(false);
    }
  }

  const onAvatarChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files!.length > 0) {
      const file = event?.target?.files![0];
      const fileUrl = URL.createObjectURL(file);
      setAvatarUrl(fileUrl);
      setAvatarFile(file);
    }
  }

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'school_name') setSchoolDetails(prevState => ({ ...prevState, name: value }));
    if (name === 'website') setSchoolDetails(prevState => ({ ...prevState, url: value }));
    if (name === 'school_address') setSchoolDetails(prevState => ({ ...prevState, address: value }));
  }

  if (error) {
    return <h1>{CONST_FETCH_PAGE_ERROR}</h1>
  }

  return (
    <>
      <PageHeader title={'Settings'} />
      <PageControls>
        <Button
          loading={isCodeGenerating}
          onClick={onCreateSchoolCodeHandler}
          primary
          size={'md'}><span>Create School Code</span>
        </Button>
      </PageControls>

      <Container>
        <NavigationBar items={categories}>
          <ButtonUpdate
            onClick={onUpdateHandler}
            size={'md'}>Update
          </ButtonUpdate>
        </NavigationBar>
        <SettingsStyled isPending={isPending}>
          <SettingsRowStyled>
            <SettingsItemStyled>
              <SchoolCode code={schoolDetails.parent_invite?.code} isLoading={isLoading} />
            </SettingsItemStyled>
          </SettingsRowStyled>

          <SettingsRowStyled>
            <SettingsItemStyled>
              <SchoolLogo
                isLoading={isLoading}
                imageUrl={avatarUrl}
                name={schoolDetails.name}
                onAvatarChangeHandler={onAvatarChangeHandler}
              />
            </SettingsItemStyled>
          </SettingsRowStyled>

          {inputs.map((input) =>
            <SettingsRowStyled key={input.id}>
              <SettingsItemStyled>
                {isLoading ? <Skeleton />
                  :
                  input.type === 'url' ?
                    <InputText {...input}
                               name={input.id}
                               text={'https://'}
                               secondary
                               onChange={onInputChangeHandler} />
                    : <Input {...input}
                             name={input.id}
                             secondary
                             onChange={onInputChangeHandler} />
                }
              </SettingsItemStyled>
            </SettingsRowStyled>
          )}
        </SettingsStyled>
      </Container>
    </>
  );
}
