import React from "react";
import {
  FeedStyled,
  FeedHeadStyled,
  FeedBodyStyled,
  FeedContentStyled,
  FeedTitleStyled,
  FeedDescStyled,
  FeedDetailsStyled,
  FeedDetailsLabelStyled,
  FeedDetailsInfoStyled,
  FeedDetailsColStyled,
  FeedImageStyled,
  FeedFigureStyled,
} from "./Feed.styled";
import { UserInfo } from "../UserInfo";
import { Button, BulletStyled } from "components";
import { DeleteIcon } from "assets/icons";
import { formatToLocaleDate, formatToLocaleTime } from "utils/helpers";
import { IFeed } from "types";
import { getFeedAuthorImage, getFeedMainImagePath } from "services";

interface FeedProps {
  feed: IFeed;
  onFeedRemoveHandler: (id: string) => void;
  deleteId: string;
}

export function Feed({ feed, deleteId, onFeedRemoveHandler }: FeedProps) {
  return (
    <FeedStyled>
      <FeedHeadStyled>
        <UserInfo
          userFirstName={feed.user.first_name}
          userLastName={feed.user.last_name}
          userAvatar={getFeedAuthorImage(feed)}
          userClass={
            feed.user.class_memberships.length && feed.user.class_memberships[0].class_id
              ? feed.user.class_memberships[0].class_id.toString()
              : "-"
          }
          createdAt={feed.created_at}
        />
        <Button
          loading={deleteId === feed.id}
          primary
          light
          size={"sm"}
          onClick={onFeedRemoveHandler.bind(null, feed.id)}
        >
          <DeleteIcon />
          <span>Delete</span>
        </Button>
      </FeedHeadStyled>

      <FeedBodyStyled>
        <FeedContentStyled>
          <FeedTitleStyled>{feed.title}</FeedTitleStyled>
          <FeedDescStyled>{feed.description}</FeedDescStyled>
          {feed.type === "event" && (
            <FeedDetailsStyled>
              <FeedDetailsColStyled>
                <FeedDetailsLabelStyled>Location</FeedDetailsLabelStyled>
                <FeedDetailsInfoStyled>
                  {feed.type_options.location}
                </FeedDetailsInfoStyled>
              </FeedDetailsColStyled>
              <FeedDetailsColStyled>
                <FeedDetailsLabelStyled>Time & Date</FeedDetailsLabelStyled>
                {feed.type_options.date && (
                  <FeedDetailsInfoStyled>
                    {formatToLocaleDate(feed.type_options.date)}
                    <BulletStyled>&bull;</BulletStyled>@{" "}
                    {formatToLocaleTime(feed.type_options.date)}
                  </FeedDetailsInfoStyled>
                )}
              </FeedDetailsColStyled>
            </FeedDetailsStyled>
          )}
        </FeedContentStyled>
        {feed.main_image && (
          <FeedFigureStyled>
            <FeedImageStyled src={getFeedMainImagePath(feed)} alt={feed.title} />
          </FeedFigureStyled>
        )}
      </FeedBodyStyled>
    </FeedStyled>
  );
}
