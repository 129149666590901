import React, { SetStateAction, useState } from 'react';
import { API } from 'api';
import {
  DataGridHeadStyled,
  DataGridRowStyled,
  DataGridTableStyled,
  DataGridTBodyStyled,
  DataGridTHeadStyled,
  DataGridCellStyled, ButtonIcon,
} from 'components';
import { RewardsTableFigureStyled } from './RewarsTable.styled';
import { IRewardItem } from 'types';
import { DeleteIcon } from 'assets/icons';
import { useUsers } from 'context';
import { useIsMounted } from 'hooks';

interface RewardsProps {
  rewards: IRewardItem[];
  setRewards: React.Dispatch<SetStateAction<IRewardItem[]>>;
}

export function RewardsTable({ rewards, setRewards }: RewardsProps) {
  const [deleteId, setDeleteId] = useState<number>();
  const { setIsPending } = useUsers();
  const [_isMounted] = useIsMounted();

  const onRemoveRewardItemHandler = async (id: number) => {
    const proceed = window.confirm(`Are you sure you want to remove the reward item?`);
    if (!proceed) return;

    setIsPending(true);
    setDeleteId(id);
    try {
      await API.delete(`/reward/items/${id}`);
      if (_isMounted.current) {
        const updatedList = rewards.filter((reward: IRewardItem) => reward.id !== id);
        setRewards(updatedList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteId(undefined)
      setIsPending(false);
    }
  };

  return (
    <DataGridTableStyled>
      <DataGridTHeadStyled>
        <DataGridRowStyled>
          {['Name', 'Photo', 'Points Value', ''].map((col, index) =>
            <DataGridHeadStyled key={index}>{col}</DataGridHeadStyled>
          )}
        </DataGridRowStyled>
      </DataGridTHeadStyled>

      <DataGridTBodyStyled>
        {rewards.map((reward: IRewardItem) =>
          <DataGridRowStyled key={reward.id}>
            <DataGridCellStyled>{reward.title}</DataGridCellStyled>
            <DataGridCellStyled>
              {reward.image_link.length ?
                <RewardsTableFigureStyled>
                  <img src={reward.image_link} alt={reward.title} />
                </RewardsTableFigureStyled> : '-'}
            </DataGridCellStyled>
            <DataGridCellStyled>{reward.purchase_price}</DataGridCellStyled>
            <DataGridCellStyled align={'right'}>
              <ButtonIcon
                loading={reward.id === deleteId}
                onClick={onRemoveRewardItemHandler.bind(null, reward.id)}>
                <DeleteIcon />
              </ButtonIcon>
            </DataGridCellStyled>
          </DataGridRowStyled>
        )}
      </DataGridTBodyStyled>
    </DataGridTableStyled>
  );
}
