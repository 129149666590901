import styled from 'styled-components';
import { rem } from 'styles/theme';

interface Props {
  marginR?: number;
}

// language=SCSS prefix=*{ suffix=}
export const PageControlsStyled = styled.div<Props>`
    display: flex;
    justify-content: flex-end;
    margin-right: ${({ marginR }) => marginR ? rem(marginR) : null};
    margin-bottom: 1rem;
    
    button {
      margin-left: ${rem(8)};
    }
`;
