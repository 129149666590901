import React, { SetStateAction } from 'react';
import { useModal } from 'hooks';
import { Button, Modal } from 'components';
import { UploadIcon } from 'assets/icons';
import { AddNewRewardForm } from './components';

interface Props {
  setCleanup: React.Dispatch<SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

export function AddNewReward({ setIsLoading, setCleanup }: Props) {
  const { isShown, toggle } = useModal();
  const content = <AddNewRewardForm
    hide={toggle}
    setIsLoading={setIsLoading}
    setCleanup={setCleanup}
  />;

  return (
    <>
      <Button onClick={toggle} size={'lg'} primary light><UploadIcon />Add new</Button>
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText={'Add New Reward Item'}
        modalContent={content} />
    </>
  )
}
