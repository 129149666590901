import React, { useState } from "react";
import { Button, FormSubmit } from "components";
import { RewardPointsFields } from "./RewardPointsFields";
import { API } from "api";
import { IClassMembership } from "types";

interface IProps {
  id: string | number;
  total: number;
  classMembership: IClassMembership[];
  rewards: any;
  setRewards: any;
  setRemoveId: (value: string | number) => void;
}

export function RemovePointsForm({
  id,
  total,
  classMembership,
  rewards,
  setRewards,
  setRemoveId,
}: IProps) {
  const [isPending, setIsPending] = useState(false);
  const [points, setPoints] = useState<number | undefined>(undefined);
  const isFormValid = points;
  const apiData = {
    points: -Math.abs(points as number),
  };

  const onRemovePointHandler = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const proceed = window.confirm(
      "Are you sure you want to remove reward point(s)?"
    );
    if (!proceed) return;

    setIsPending(true);
    const updatedRewards = rewards.map((reward: any) => {
      if (reward.user.id === id) {
        return {
          ...reward,
          points: {
            ...reward.points,
            total: (reward.points.total -= points!),
          },
        };
      }

      return reward;
    });

    try {
      await API.post(`/reward/leaderboard/${id}/add-points`, apiData);
      setRewards(updatedRewards);
      setRemoveId(id);

      setTimeout(() => {
        setRemoveId("");
      }, 800);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form>
      <RewardPointsFields
        total={total}
        classMembership={classMembership}
        setPoints={setPoints}
      />

      <FormSubmit>
        <Button
          disabled={!isFormValid}
          size={"md"}
          primary
          loading={isPending}
          onClick={onRemovePointHandler}
        >
          <span>Remove</span>
        </Button>
      </FormSubmit>
    </form>
  );
}
