import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'assets/icons';
import { BreadcrumbsStyled, BreadcrumbsCurrentStyled } from './Breadcrumbs.styled';

interface BreadcrumbsProps {
  title?: string | undefined;
  url: string;
  children: React.ReactNode;
  titleChildren?: React.ReactNode
}

export function Breadcrumbs({ children, url, titleChildren }: BreadcrumbsProps) {
  return (
    <BreadcrumbsStyled>
      <Link to={`/${url}`} state={{ test: 'test' }}>
        {children}
      </Link>
      <ChevronRight />
      <BreadcrumbsCurrentStyled>{titleChildren}</BreadcrumbsCurrentStyled>
    </BreadcrumbsStyled>
  );
}
