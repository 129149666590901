import styled from 'styled-components';
import { rem } from 'styles/theme';

// language=SCSS prefix=*{ suffix=}
export const ToggleSwitchStyled = styled.label`
    position: relative;
    display: inline-block;
    width: ${rem(36)};
    height: ${rem(20)};

    input[type="checkbox"] {
      left: -1px;
      opacity: 0;
      position: absolute;

      &:checked + span::before {
        transform: translateX(${rem(16)});
        background-color: #fff;
      }

      &:checked + span {
        background-color: ${({ theme }) => theme.colors.primary.lightDark};
      }
      
      &:focus-visible + span::before {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary.lightDark};
      }
    }
`;

export const ToggleSwitchItemStyled = styled.span`
    position: absolute;
    cursor: pointer;
    background-color: rgba(228, 231, 236, 0.7);
    border-radius: ${rem(18)};
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    transition: background-color 0.2s ease;

    &::before {
      position: absolute;
      content: "";
      left: ${rem(2)};
      top: ${rem(2)};
      width: 1rem;
      height: 1rem;
      background-color: #fff;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
`;
