import { createGlobalStyle } from 'styled-components'
import { rem } from './theme';

// language=SCSS prefix=*{ suffix=}
const GlobalStyle = createGlobalStyle`
    * {
      &,
      &::before,
      &::after {
        box-sizing: inherit;
      }
    }

    html {
      box-sizing: border-box;
      font-size: 100%;
      height: 100%;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: ${({ theme }) => theme.typography.body.lineHeight};
      background-color: ${({ theme }) => theme.colors.tertiary.light2};
      color: ${({ theme }) => theme.colors.secondary.darkest};
      font-size: ${({ theme }) => theme.typography.body.fontSize};
    }
    
    body,
    button {
      font-family: ${({ theme }) => theme.typography.body.fontFamily}, sans-serif;
    }

    h1 {
      font-size: ${({ theme }) => theme.typography.h1.size};
      line-height: ${({ theme }) => theme.typography.h1.lineHeight};
      letter-spacing: ${({ theme }) => theme.typography.h1.letterSpacing};
      letter-spacing: ${props => (props.theme.typography.h1.letterSpacing)};
      margin-top: 0;
      margin-bottom: ${rem(8)};
    }
    
    h2 {
      font-size: ${({ theme }) => theme.typography.h2.size};
      line-height: ${({ theme }) => theme.typography.h1.lineHeight};
      letter-spacing: ${props => (props.theme.typography.h2.letterSpacing)};
      color: ${({ theme }) => theme.colors.secondary.darkBlack};
      margin-top: 0;
      margin-bottom: ${rem(32)};
    }
    
    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    
    table {
      border-spacing: 0; 
      width: 100%;
    }
    
    hr {
      margin: 2rem 0;
      border: none;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.secondary.light};
    }
    
    figure {
      margin: 0;
    }
    
    img {
      vertical-align: middle;
      max-width: 100%;
      height: auto;
    }
`;

export default GlobalStyle;

