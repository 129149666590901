import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  TextMessage,
  PageHeader,
  DataGrid,
  SkeletonTable,
  PageControls,
  LoadMore
} from 'components';

import { CONST_FETCH_PAGE_ERROR, CONST_USER_FETCH_TIMEOUT } from 'utils/constants';
import { API } from 'api';
import { PointsTable } from './components/PointsTable';
import { AddNewReward, RewardsTable } from './components';
import { IReward, IRewardItem } from 'types';
import { useUsers } from 'context';
import { getLastUrlSegment } from 'utils/helpers';

const initialPointsState: IReward[] = [];
const initialRewardsState: IRewardItem[] = [];

export function Rewards() {
  const { setIsPending, setIsPaginating, query } = useUsers();
  const [points, setPoints] = useState(initialPointsState);
  const [rewards, setRewards] = useState(initialRewardsState);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoadMoreShown, setIsLoadMoreShown] = useState(false);
  const [cleanup, setCleanup] = useState(false);
  const { pathname } = useLocation();
  const categoryToFetch = getLastUrlSegment(pathname);
  const isPoints = categoryToFetch === 'points';
  const category = isPoints ? 'leaderboard' : 'items';

  const categories = [
    { path: 'rewards/points', name: 'Points' },
    { path: 'rewards/reward-items', name: 'Reward Items' },
  ];

  useEffect(() => {
    let isApiSubscribed = true;
    const fetchData = async () => {
      try {
        const { data } = await API.get(`/reward/${category}?limit=10&offset=${offset}`);
        if (isApiSubscribed) {
          if (data.results.length >= 10) {
            setIsLoadMoreShown(true);
          } else {
            setIsLoadMoreShown(false);
          }
          if (isPoints) {
            setPoints((points) => [...points, ...data.results]);
          } else {
            setRewards((rewards) => [...rewards, ...data.results])
          }
        }
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setIsLoading(false);
        setIsPending(false);
        setIsPaginating(false);
      }
    }

    const timeout = setTimeout(() => {
      void fetchData();
    }, CONST_USER_FETCH_TIMEOUT);

    return () => {
      clearTimeout(timeout);
      isApiSubscribed = false;
    };
  }, [offset, query, setIsPending, setIsPaginating, category, isPoints]);

  useEffect(() => {
    return () => {
      setPoints(points => [...[]]);
      setRewards(rewards => [...[]]);
      setOffset(offset => 0);
    }
  }, [pathname, setPoints, cleanup]);


  if (points === undefined || error) {
    return (
      <PageHeader title={CONST_FETCH_PAGE_ERROR} />
    )
  }

  return (
    <>
      <PageHeader titleChildren={'Rewards and Points'} />
      <PageControls>
        <AddNewReward setIsLoading={setIsLoading} setCleanup={setCleanup} />
      </PageControls>

      <DataGrid
        searchUrl={`/reward/${category}?`}
        searchType={isPoints ? '' : 'rewards'}
        categories={categories}
        setLoading={setIsLoading}
        setIsLoadMoreShown={setIsLoadMoreShown}
        setSearchData={isPoints ? setPoints : setRewards}>

        {isLoading ? <SkeletonTable rows={10} columns={4} /> :
          points.length ? <PointsTable
              rewards={points}
              setRewards={setPoints}
            /> :
            rewards.length ? <RewardsTable
                rewards={rewards}
                setRewards={setRewards} /> :
              <TextMessage message={'No data'} />}
      </DataGrid>

      {isLoading ? null : isLoadMoreShown &&
          <LoadMore setOffset={setOffset} />
      }
    </>
  );
}
