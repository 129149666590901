import React, { SetStateAction, useState } from 'react';
import {
  AdsCardsStyled,
  AdsCardsListStyled,
  AdsCardsListItemStyled
} from './AdsCards.styled';
import { AdsCard } from '../AdsCard';
import { API } from 'api';
import { useIsMounted } from 'hooks';
import { ISponsoredAds } from 'types';

interface AdsCardsProps {
  adsCards: ISponsoredAds[];
  setAdsData: React.Dispatch<SetStateAction<ISponsoredAds[]>>;
}

export function AdsCards({ adsCards, setAdsData }: AdsCardsProps) {
  const [isPending, setIsPending] = useState(false);
  const [approveId, setApproveId] = useState<number>();
  const [removeId, setRemoveId] = useState<number>();
  const [_isMounted] = useIsMounted()

  const onDeleteHandler = async (id: number) => {
    const proceed = window.confirm('Are you sure you want to delete the sponsored ad?');
    if (!proceed) return;

    setRemoveId(id);
    setIsPending(true);

    try {
      await API.delete(`/ads/${id}`);
      if (_isMounted.current) {
        const updatedAds = adsCards.filter((card: ISponsoredAds) => card.id !== id);
        setAdsData(updatedAds);
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsPending(false);
      setRemoveId(undefined);
    }
  }

  const onApproveHandler = async (id: number) => {
    setApproveId(id)
    setIsPending(true);

    try {
      await API.post(`/ads/${id}/approve`);
      const updatedCards = adsCards.map((card: ISponsoredAds) => {
        if (card.id === id) {
          return { ...card, approved: true }
        }

        return card;
      });
      setAdsData(updatedCards);
    } catch (e) {
      console.error(e);
    } finally {
      setIsPending(false);
      setApproveId(undefined);
    }
  }

  return (
    <AdsCardsStyled isPending={isPending}>
      <AdsCardsListStyled>
        {adsCards.map((card: ISponsoredAds) =>
          <AdsCardsListItemStyled key={card.id}>
            <AdsCard
              approveId={approveId}
              removeId={removeId}
              onDeleteHandler={onDeleteHandler}
              onApproveHandler={onApproveHandler}
              card={card} />
          </AdsCardsListItemStyled>
        )}
      </AdsCardsListStyled>
    </AdsCardsStyled>
  )
}
