import React from 'react';
import {
  RewardPointsUserInfoItemStyled,
  RewardPointsUserInfoLabelStyled,
  RewardPointsUserInfoStyled
} from './RewardPointsForm.styled';
import { StarIcon, ClassIcon } from 'assets/icons';

import { InputGroup, InputLabel, InputNumber } from 'components';
import { parseNumberInput } from 'utils/helpers';
import { IClassMembership } from 'types';

interface RewardPointsUserInfoProps {
  total: number;
  classMembership: IClassMembership[];
  setPoints: (value: number) => void;
}

export function RewardPointsFields({
                                     total,
                                     classMembership,
                                     setPoints
                                   }: RewardPointsUserInfoProps) {

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, value } = e.target;
    if (type === 'number') {
      setPoints(parseNumberInput(value));
    }
  }

  return (
    <>
      <RewardPointsUserInfoStyled>
        <RewardPointsUserInfoItemStyled>
          <RewardPointsUserInfoLabelStyled>
            <StarIcon />Reward Points
          </RewardPointsUserInfoLabelStyled>
          {total}
        </RewardPointsUserInfoItemStyled>

        <RewardPointsUserInfoItemStyled>
          <RewardPointsUserInfoLabelStyled>
            <ClassIcon />Class
          </RewardPointsUserInfoLabelStyled>
          {classMembership.length ? classMembership[0].class_id : '-'}
        </RewardPointsUserInfoItemStyled>
      </RewardPointsUserInfoStyled>

      <hr />

      <InputGroup width={255}>
        <InputLabel htmlFor={'Amount'}>Amount</InputLabel>
        <InputNumber onChange={onChangeHandler}
                     id={'amount'}
                     placeholder={'000'}
                     min={0}
                     name={'amount'}
                     type={'number'} />
      </InputGroup>
    </>
  )
}
