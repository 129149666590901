import styled from 'styled-components';
import { rem } from 'styles/theme';

// language=SCSS prefix=*{ suffix=}
export const SidebarStyled = styled.div`
  width: ${rem(320)};
  padding: ${rem(56)} ${rem(24)} ${rem(54)};
  background-color: ${({ theme }) => theme.colors.primary.dark};
  flex-shrink: 0;
`;

export const LogoStyled = styled.div`
  width: ${rem(151)};
  margin-left: ${rem(16)};
  margin-bottom: ${rem(44)};
`;
