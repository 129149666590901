import { parseSessionStorage, queryString } from 'utils/helpers';
import { CONST_AUTH } from 'utils/constants';

export const getAuthSession = () => {
  const queryParams = {
    token_ID: queryString(CONST_AUTH.TOKEN_ID),
    school_ID: queryString(CONST_AUTH.SCHOOL_ID)
  }

  if (queryParams.token_ID && queryParams.school_ID && !sessionStorage.getItem(CONST_AUTH.SCHOOL_CREDS)) {
    return {
      token_id: queryParams.token_ID,
      school_id: queryParams.school_ID
    };
  }

  if (sessionStorage.getItem(CONST_AUTH.SCHOOL_CREDS)) {
    const schoolData = parseSessionStorage(sessionStorage.getItem(CONST_AUTH.SCHOOL_CREDS))

    return {
      token_id: schoolData?.token_id,
      school_id: schoolData?.school_id
    };
  }
};
