import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import { rem } from '../../styles/theme';

// language=SCSS prefix=*{ suffix=}
export const NavigationStyled = styled.nav``;

export const NavigationMenuStyled = styled.ul``;

export const NavigationItemStyled = styled.li``;

export const NavigationLinkStyled = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: ${rem(16)};
    font-weight: bold;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.primary.lightest};
    text-decoration: none;
    border-radius: ${rem(16)};
    
    &.active,
    &:hover {
      background-color: rgba(25, 65, 133, 0.2);
      color: #fafafa;
    }
   
    svg {
      margin-right: ${rem(16)};
    }
`;
