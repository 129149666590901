import React, { SetStateAction, useState } from 'react';
import { API } from 'api';
import {
  FormSubmit,
  Button,
  Input,
  InputNumber,
  InputGroup, InputLabel
} from 'components';
import {
  AddNewRewordFormUploadStyled,
  UploadButtonIconStyled,
  RewardUploadFileInfoStyled,
  RewardUploadFileInfoNameStyled,
  RewardUploadFileInfoSizeStyled,
  RewardUploadFileInfoInProgressStyled,
  RewardUploadFileInfoRemoveStyled,
  RewardUploadFileInfoProgressStyled,
  RewardUploadFileInfoProgressBarStyled
} from './AddNewRewordForm.styled';
import { UploadRewardIcon, FileIcon, DoneIcon, CrossIcon } from 'assets/icons';
import { parseNumberInput } from 'utils/helpers';
import { useUsers } from 'context';

interface AddNewRewardFormProps {
  hide: () => void;
  setCleanup: React.Dispatch<SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

interface FileInfoProps {
  name: string;
  size: string;
}

export function AddNewRewardForm({ hide, setIsLoading, setCleanup }: AddNewRewardFormProps) {
  const formData = new FormData();
  const { setQuery, query } = useUsers();
  const [isPending, setIsPending] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageSelected, setImageSelected] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [isUploadProgress, setIsUploadProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [pointsValue, setPointsValue] = useState<number | string | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);
  const [fileInfo, setFileInfo] = useState<FileInfoProps | null>(null);

  const isFormValid = title.length && pointsValue && imageSelected;

  const onAddNewRewardHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const proceed = window.confirm('Are you sure you want to add new reward item?');
    if (!proceed) return;

    setIsPending(true);
    formData.append('image', file as File);
    formData.append('title', title);
    formData.append('purchase_price', pointsValue as string);
    if (description.length) {
      formData.append('description', description);
    }

    try {
      await API.post('/reward/items', formData);
      hide();
      setQuery(!query);
      setIsLoading(true)
      setCleanup(true);
    } catch (e: any) {
      console.error(e);
      setIsPending(false);
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'title') {
      setTitle(value);
    } else if (name === 'description') {
      setDescription(value);
    } else if (name === 'points_value') {
      setPointsValue(parseNumberInput(value));
    }
  }

  const sizeBytesToKb = (size: number) => {
    return Math.ceil(size / 1024).toFixed(0);
  }

  const onFileInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const PROGRESS_TIMEOUT = 1000;
    const { files } = e.target;

    if (files && files[0]) {
      const { name, size } = files![0];

      setIsUploadProgress(true);
      setFileInfo(prevState => ({
        ...prevState,
        name,
        size: sizeBytesToKb(size)
      }));

      const time = setInterval(() => {
        setProgress(prevState => prevState + 1);
      }, 10);

      setTimeout(() => {
        setTimeout(() => {
          setImageSelected(true);
          setFile(files[0]);
          setImageUploaded(true);
          setProgress(0);
        }, 300);
        clearInterval(time);
      }, PROGRESS_TIMEOUT);
    }
  }

  const onRemoveFileHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFile(null);
    setFileInfo(null);
    setImageSelected(false);
    setImageUploaded(false);
    setIsUploadProgress(false);
  }

  return (
    <form>
      <Input onChange={onChangeHandler} label={'Title*'} id={'title'} name={'title'} type={'text'} />
      <Input onChange={onChangeHandler} label={'Description'} id={'description'} name={'description'} type={'text'} />
      <InputGroup>
        <InputLabel htmlFor={'points_value'}>Points Value*</InputLabel>
        <InputNumber
          onChange={onChangeHandler}
          type={'number'}
          id={'points_value'}
          name={'points_value'} />
      </InputGroup>

      <InputGroup columnReverse>
        <InputLabel htmlFor={'file'}>Upload Image*</InputLabel>
        <AddNewRewordFormUploadStyled>
          {isUploadProgress ?
            <RewardUploadFileInfoStyled>
              <div>
                <UploadButtonIconStyled>
                  <FileIcon />
                </UploadButtonIconStyled>
                <div>
                  <RewardUploadFileInfoNameStyled>{fileInfo?.name}</RewardUploadFileInfoNameStyled>
                  <RewardUploadFileInfoSizeStyled>{fileInfo?.size} KB</RewardUploadFileInfoSizeStyled>
                  {!imageUploaded &&
                  <RewardUploadFileInfoProgressStyled>
                    <RewardUploadFileInfoProgressBarStyled value={progress} max="100" />{progress}%
                  </RewardUploadFileInfoProgressStyled>
                  }
                </div>
              </div>

              <div>
                {!imageUploaded ?
                  <RewardUploadFileInfoInProgressStyled>
                    <DoneIcon />
                  </RewardUploadFileInfoInProgressStyled>
                  :
                  <RewardUploadFileInfoRemoveStyled
                    onClick={onRemoveFileHandler}><CrossIcon /></RewardUploadFileInfoRemoveStyled>
                }
              </div>
            </RewardUploadFileInfoStyled>
            :
            <label htmlFor={'file'}>
              <UploadButtonIconStyled>
                <UploadRewardIcon />
              </UploadButtonIconStyled>
              Click to upload
              <input
                onChange={onFileInputChangeHandler}
                type='file'
                accept="image/*"
                id={'file'}
                name={'file'}
              />
            </label>
          }

        </AddNewRewordFormUploadStyled>

      </InputGroup>

      <FormSubmit>
        <Button disabled={!isFormValid}
                size={'md'}
                primary
                loading={isPending}
                onClick={onAddNewRewardHandler}>
          <span>Add</span>
        </Button>
      </FormSubmit>
    </form>
  );
}
