import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  ReactNode
} from 'react';
import { IUser } from 'types'

interface IUsersContextState {
  users: IUser[];
  setUsers: Dispatch<SetStateAction<IUser[]>>;
  query: boolean,
  setQuery: Dispatch<SetStateAction<boolean>>;
  isUploading: boolean;
  setIsUploading: Dispatch<SetStateAction<boolean>>;
  isPending: boolean;
  setIsPending: Dispatch<SetStateAction<boolean>>;
  isPaginating: boolean;
  setIsPaginating: Dispatch<SetStateAction<boolean>>
}

export const UsersContext = createContext({} as IUsersContextState);

export const useUsers = () => useContext(UsersContext);

export const UsersProvider = ({ children }: { children: ReactNode }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [query, setQuery] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);

  const value = {
    users,
    setUsers,
    query,
    setQuery,
    isUploading,
    setIsUploading,
    isPending,
    setIsPending,
    isPaginating,
    setIsPaginating
  }
  return (
    <UsersContext.Provider
      value={value}
    >
      {children}
    </UsersContext.Provider>
  );
};
