import React from 'react';
import {
  UserInfoStyled,
  UserInfoBodyStyled,
  UserInfoNameStyled,
  UserInfoMetaStyled
} from './UserInfo.styled';
import { Avatar, BulletStyled } from 'components';
import { formatToLocaleDate } from 'utils/helpers';

interface Props {
  userAvatar?: string;
  userFirstName: string;
  userLastName: string;
  userClass: string;
  createdAt: string;
}

export function UserInfo({
                           userAvatar,
                           userFirstName,
                           userLastName,
                           userClass,
                           createdAt
                         }: Props) {
  const userFullName = `${userFirstName} ${userLastName}`;

  return (
    <UserInfoStyled>

      <Avatar
        src={userAvatar}
        alt={userFullName}
        radius={8}
        width={48}
        height={48} />

      <UserInfoBodyStyled>
        <UserInfoNameStyled>{userFullName}</UserInfoNameStyled>
        <UserInfoMetaStyled>{formatToLocaleDate(createdAt, 'long')}<BulletStyled>&bull;</BulletStyled>{userClass}
        </UserInfoMetaStyled>
      </UserInfoBodyStyled>
    </UserInfoStyled>
  );
}
