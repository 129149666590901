import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLastUrlSegment } from 'utils/helpers';
import {
  PageHeader,
  DataGrid,
  SkeletonTable,
  TextMessage,
  LoadMore
} from 'components';
import { ClubAndGroupsTable } from './components';
import { CONST_FETCH_PAGE_ERROR, CONST_USER_FETCH_TIMEOUT } from 'utils/constants';
import { IClubsClasses } from 'types';
import { API } from 'api';
import { useUsers } from 'context';

const categories = [
  { path: 'clubs-and-classes/clubs', name: 'Clubs' },
  { path: 'clubs-and-classes/classes', name: 'Classes' },
];

const initialState: IClubsClasses[] = []

export function ClubsAndClasses() {
  const { setIsPending, setIsPaginating } = useUsers();
  const { pathname } = useLocation();
  const slug = getLastUrlSegment(pathname);
  const [clubsClasses, setClubsClasses] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoadMoreShown, setIsLoadMoreShown] = useState(false);

  const URL = `/${slug}?limit=10&offset=${offset}`;

  useEffect(() => {
    let isApiSubscribed = true;
    const fetchData = async () => {
      try {
        const response = await API.get(URL);
        const { results } = response.data;
        console.log(results)
        if (isApiSubscribed) {
          setClubsClasses(clubsClasses => [...clubsClasses, ...results]);
          if (results.length >= 10) {
            setIsLoadMoreShown(true);
          } else {
            setIsLoadMoreShown(false);
          }
        }
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setIsLoading(false);
        setIsPending(false);
        setIsPaginating(false);
      }
    };

    const timeout = setTimeout(() => {
      void fetchData();
    }, CONST_USER_FETCH_TIMEOUT);

    return () => {
      clearTimeout(timeout);
      isApiSubscribed = false;
    };
  }, [URL, setIsPending, setIsPaginating]);

  useEffect(() => {
    return () => {
      setClubsClasses(users => [...[]]);
      setOffset(offset => 0);
    }
  }, [pathname, setClubsClasses]);

  if (clubsClasses === undefined || error) {
    return (
      <PageHeader title={CONST_FETCH_PAGE_ERROR} />
    );
  }

  return (
    <>
      <PageHeader mb={74} title={'Clubs & Classes'} />
      <DataGrid
        setIsLoadMoreShown={setIsLoadMoreShown}
        searchUrl={`/${slug}?`}
        setSearchData={setClubsClasses}
        setLoading={setIsLoading}
        categories={categories}>

        {isLoading ? <SkeletonTable rows={10} columns={3} /> :
          clubsClasses.length ? <ClubAndGroupsTable
              setClubsClasses={setClubsClasses}
              clubsClasses={clubsClasses} /> :
            <TextMessage message={'No data'} />}
      </DataGrid>


      {isLoading ? null : isLoadMoreShown &&
          <LoadMore setOffset={setOffset} />
      }
    </>
  );
}
