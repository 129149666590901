import React, { SetStateAction } from 'react';
import { useModal } from 'hooks/useModal';
import { Modal, Button } from 'components';
import { PlusIcon } from 'assets/icons/plus';
import { AddUserForm } from './components/AddUserForm';

interface AddUserProps {
  setCleanup: React.Dispatch<SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

export function AddUser({ setCleanup, setIsLoading }: AddUserProps) {
  const { isShown, toggle } = useModal();
  const content = <AddUserForm setIsLoading={setIsLoading} setCleanup={setCleanup} hide={toggle} />;

  return (
    <>
      <Button onClick={toggle} size={'lg'} primary light><PlusIcon />Add new</Button>
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText={'Add New User'}
        modalContent={content} />
    </>
  );
}
