import React from 'react';
import { AvatarStyled, AvatarImgStyled } from './Avatar.styled';

interface AvatarProps {
  src?: string;
  alt: string;
  width: number;
  height: number;
  radius?: number;
}

export function Avatar({ width, height, src, alt, radius }: AvatarProps) {
  return (
    <AvatarStyled width={width} height={height} radius={radius}>
      {src?.length ? <AvatarImgStyled src={src} alt={alt} /> : alt.charAt(0).toUpperCase()}
    </AvatarStyled>
  );
}
