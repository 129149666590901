import React from 'react';
import {
  SkeletonStyled,
  SkeletonRowStyled,
  SkeletonCardStyled,
  SkeletonCardImageStyled,
  SkeletonCardBodyStyled,
  SkeletonAvatarStyled,
  SkeletonFeedStyled,
  SkeletonFeedHeadStyled,
  SkeletonFeedUserInfoStyled,
  SkeletonFeedContentStyled,
  SkeletonFeedBodyStyled,
  SkeletonFeedDetailsStyled
} from './Skeleton.styled';

interface SkeletonProps {
  items?: number;
  width?: number;
  height?: number;
}

interface SkeletonTableProps {
  rows: number;
  columns: number;
}

export function Skeleton({ items, width, height }: SkeletonProps) {
  const skeletons = Array.from(Array(items).keys());
  return (
    <>
      {skeletons.map((skeleton) => <SkeletonStyled width={width} height={height} key={skeleton} />)}
    </>
  )
}

export function SkeletonTable({ rows, columns }: SkeletonTableProps) {
  const skeletonRows = Array.from(Array(rows).keys());
  const skeletonColumns = Array.from(Array(columns).keys());

  return (
    <>
      {skeletonRows.map((row) =>
        <SkeletonRowStyled key={row}>
          {skeletonColumns.map((column) => <SkeletonStyled key={column} />)}
        </SkeletonRowStyled>
      )}
    </>
  );
}

export function SkeletonCard({ items }: SkeletonProps) {
  const skeletons = Array.from(Array(items).keys());

  return (
    <>
      {skeletons.map((skeleton) =>
        <SkeletonCardStyled key={skeleton}>
          <SkeletonCardImageStyled />
          <SkeletonCardBodyStyled>
            <Skeleton width={200} />
            <Skeleton />
            <Skeleton width={200} />
            <Skeleton />
          </SkeletonCardBodyStyled>
        </SkeletonCardStyled>
      )}
    </>
  );
}

export function SkeletonFeed({ items }: SkeletonProps) {
  const skeletons = Array.from(Array(items).keys());

  return (
    <>
      {skeletons.map((skeleton) =>
        <SkeletonFeedStyled key={skeleton}>
          <SkeletonFeedHeadStyled>
            <SkeletonFeedUserInfoStyled>
              <SkeletonAvatarStyled width={48} height={48} radius={8} />
              <div>
                <Skeleton width={100} height={12} />
                <Skeleton width={200} height={12} />
              </div>
            </SkeletonFeedUserInfoStyled>

            <Skeleton width={85} height={36} />
          </SkeletonFeedHeadStyled>

          <SkeletonFeedBodyStyled>
            <SkeletonFeedContentStyled>
              <Skeleton width={150} height={12} />
              <Skeleton height={12} />
              <Skeleton height={12} />
              <SkeletonFeedDetailsStyled>
                <div>
                  <Skeleton width={40} height={12} />
                  <Skeleton width={70} height={12} />
                </div>

                <div>
                  <Skeleton width={40} height={12} />
                  <Skeleton width={70} height={12} />
                </div>
              </SkeletonFeedDetailsStyled>
            </SkeletonFeedContentStyled>
            <SkeletonCardImageStyled />
          </SkeletonFeedBodyStyled>
        </SkeletonFeedStyled>
      )}
    </>
  );
}
