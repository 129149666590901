import React, { useState } from 'react';
import {
  InputGroup,
  InputLabel,
  Skeleton
} from 'components';
import {
  SchoolCodeToastStyled,
  SchoolCodeBtnStyled
} from './SchoolCode.styled';
import { CopyIcon } from 'assets/icons';

interface SchoolCodeProps {
  code: string;
  isLoading: boolean;
}

export function SchoolCode({ code, isLoading }: SchoolCodeProps) {
  const [showToast, setShowToast] = useState(false);

  const onCopyToClipBoardHandler = async (e: any) => {
    setShowToast(true);
    navigator.clipboard?.writeText(code);

    setTimeout(() => {
      setShowToast(false);
    }, 1200);
  }

  return (
    <>
      <InputGroup nojustify>
        <InputLabel htmlFor={'School Code'}>
          {isLoading ? <Skeleton width={102} /> : 'School Code'}
        </InputLabel>
        <div>
          {isLoading ? <Skeleton width={74} height={22} /> :
            code ?
              <SchoolCodeBtnStyled
                disabled={showToast}
                onClick={onCopyToClipBoardHandler}
                name={'School Code'}><CopyIcon />{code}</SchoolCodeBtnStyled>
              : '-'}
        </div>
      </InputGroup>

      <SchoolCodeToastStyled isVisible={showToast}>Copied to clipboard</SchoolCodeToastStyled>
    </>
  );
}
