import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { getLastUrlSegment } from 'utils/helpers';
import { useUsers } from 'context';
import {
  PageHeader,
  PageControls,
  FileUpload,
  AddUser,
  DataGrid,
  TextMessage,
  SkeletonTable, LoadMore
} from 'components';

import { UserTable } from './components';
import { CONST_FETCH_PAGE_ERROR, CONST_USER_FETCH_TIMEOUT } from 'utils/constants';
import { API } from 'api';

const categories = [
  { path: 'users/all', name: 'All Users' },
  { path: 'users/teachers', name: 'Teachers' },
  { path: 'users/students', name: 'Students' },
  { path: 'users/parents', name: 'Parents' },
  { path: 'users/moderators', name: 'Moderators' },
];

interface ObjectLiteral {
  [key: string | number]: string
}

const currentCategory: ObjectLiteral = {
  'all': 'all',
  'teachers': 'teacher',
  'students': 'student',
  'parents': 'parent',
  'moderators': 'school_owner',
}

export function Users() {
  const { pathname } = useLocation();
  const category = getLastUrlSegment(pathname);
  const { users, setUsers, query, setIsPending, setIsPaginating } = useUsers();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [offset, setOffset] = useState(0);
  const [cleanup, setCleanup] = useState(false);
  const [isLoadMoreShown, setIsLoadMoreShown] = useState(false);

  const categoryToFetch = currentCategory[category!] !== 'all' ? `filters[roles][]=${currentCategory[category!]}` : '';
  const URL = `/members?limit=10&offset=${offset}&${categoryToFetch}`;

  useEffect(() => {
    let isApiSubscribed = true;
    const fetchUsers = async () => {
      try {
        const response = await API.get(URL);
        const { results } = response.data;
        if (isApiSubscribed) {
          if (results.length >= 10) {
            setIsLoadMoreShown(true);
          } else {
            setIsLoadMoreShown(false);
          }
          setUsers((users) => [...users, ...results]);
        }
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setIsLoading(false);
        setIsPending(false);
        setIsPaginating(false);
      }
    };

    const timeout = setTimeout(() => {
      void fetchUsers();
    }, CONST_USER_FETCH_TIMEOUT);

    return () => {
      clearTimeout(timeout);
      isApiSubscribed = false;
    };
  }, [URL, query, setUsers, setIsPending, setIsPaginating]);

  useEffect(() => {
    return () => {
      setUsers(users => [...[]]);
      setOffset(offset => 0);
      setCleanup(false);
    }
  }, [pathname, setUsers, cleanup]);

  if (error || users === undefined) {
    return (
      <PageHeader title={CONST_FETCH_PAGE_ERROR} />
    )
  }

  return (
    <>
      <PageHeader title={'Users'} />
      <PageControls>
        <FileUpload setIsLoading={setIsLoading} setCleanup={setCleanup} />
        <AddUser setIsLoading={setIsLoading} setCleanup={setCleanup} />
      </PageControls>

      <DataGrid searchUrl={`/members${categoryToFetch.length ? `?${categoryToFetch}&` : '?'}`}
                setLoading={setIsLoading}
                setIsLoadMoreShown={setIsLoadMoreShown}
                categories={categories}
                setSearchData={setUsers}>
        {isLoading ? <SkeletonTable rows={10} columns={6} /> :
          users.length ? <UserTable category={category} /> : <TextMessage message={'No data'} />}
      </DataGrid>

      {isLoading ? null : isLoadMoreShown &&
          <LoadMore setOffset={setOffset} />
      }
    </>
  );
}
