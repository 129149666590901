import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';

interface Props {
  nojustify?: boolean;
  width?: number;
  columnReverse?: boolean;
}

interface InputFieldProps {
  secondary?: boolean;
}

// language=SCSS prefix=*{ suffix=}
const textMixin = css<InputFieldProps>`
    letter-spacing: 0.75px;
    color: ${(props) => props.secondary ? props.theme.colors.secondary.darkBlack : '#000'};
`;

const flexMixin = css`
    display: flex;
    align-items: center;
`;

export const inputMixin = css<InputFieldProps>`
    -webkit-appearance: none;
    padding: ${rem(8)} ${rem(14)};
    background-color: ${(props) => props.secondary ? '#fff' : props.theme.colors.tertiary.light};
    border-radius: 0.5rem;
    display: block;
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    width: 100%;
    outline-color: ${({ theme }) => theme.colors.primary.light};
    border: 1px solid ${(props) => props.secondary ? '#d0d5dd' : 'transparent'};
    caret-color: ${({ theme }) => theme.colors.primary.dark};
    max-width: ${rem(327)};
    height: ${rem(40)};
    ${({ secondary }) => secondary && css`
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    `}
`;

export const InputGroup = styled.div<Props>`
    ${flexMixin};
    justify-content: ${({ nojustify }) => !nojustify ? 'space-between' : null};
    position: relative;
    max-width: ${({ width }) => width ? rem(width) : null};

    &:not(:first-of-type) {
      margin-top: 1.5rem;
    }
    
    & > button {
      position: absolute;
      right: 0;
      
      &:disabled {
        background-color: ${({ theme }) => theme.colors.secondary.light};
        color: ${({ theme }) => theme.colors.secondary.darkish};
      }
    }
    
    ${(props) => props.columnReverse && css`
      flex-direction: column;
      align-items: flex-start;
      
      label {
        margin-bottom: 1rem;
      }
    `}
`;


export const InputLabel = styled.label`
    ${textMixin};
`;

export const InputGroupSub = styled.div`
    ${flexMixin};
  
    & > ${InputLabel} {
      margin-right: 2rem;
    }
    
    select {
      height: 40px;
    }
    
    & + div {
      ${flexMixin};
      margin-left: ${rem(40)};
    }
`;

export const InputField = styled.input`
    ${textMixin};
    ${inputMixin};
`;

export const InputNumber = styled(InputField).attrs({
  type: 'number',
})`
    height: ${rem(38)};
    width: ${rem(126)};
    text-align: center;
`;

export const FormSubmit = styled.div`
    margin-top: ${rem(40)};
    text-align: center;
`;

export const FormErrorMessageStyled = styled.div`
    color: ${({ theme }) => theme.colors.error.dark};
    font-size: ${rem(14)};
    
    &:not(:last-of-type) {
      margin-bottom: ${rem(2)};
    }
    
    &:first-letter {
      text-transform: capitalize;
    }
`;

export const InputGroupText = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    span {
      background-color: #fff;
      height: 100%;
      border-top-left-radius: ${rem(8)};
      border-bottom-left-radius: ${rem(8)};
      border: 1px solid #d0d5dd;
      padding: ${rem(8)} ${rem(14)};
      color: ${({ theme }) => theme.colors.secondary.darkish};
    }
    
    input {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
`;
