import React from "react";
import { useUsers } from "context/UsersContext";
import { DeleteIcon } from "assets/icons";
import { DataGridRowStyled, DataGridCellStyled, ButtonIcon } from "components";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import { isUserModerator, renderDate } from "utils/helpers";

export function All({
  onRemoveUserHandler,
  deleteId,
  onToggleRoleHandler,
}: any) {
  const { users } = useUsers();

  return (
    <>
      {users.map((user) => (
        <DataGridRowStyled key={user.id}>
          <DataGridCellStyled>{`${user.first_name} ${user.last_name}`}</DataGridCellStyled>
          <DataGridCellStyled>
            <span>{user.roles[0]}</span>
          </DataGridCellStyled>
          <DataGridCellStyled>
            {renderDate(user.signup_date)}
          </DataGridCellStyled>
          <DataGridCellStyled>
            {user.class_memberships.length && user.class_memberships[0].class_id
              ? user.class_memberships[0].class_id
              : "-"}
          </DataGridCellStyled>
          <DataGridCellStyled>
            <ToggleSwitch
              isToggled={isUserModerator(user.roles)}
              onChange={onToggleRoleHandler.bind(null, user.id)}
            />
          </DataGridCellStyled>
          <DataGridCellStyled align={"right"}>
            <ButtonIcon
              loading={user.id === deleteId}
              onClick={onRemoveUserHandler.bind(null, user.id)}
            >
              <DeleteIcon />
            </ButtonIcon>
          </DataGridCellStyled>
        </DataGridRowStyled>
      ))}
    </>
  );
}
