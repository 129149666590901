import React, {
  SetStateAction,
  useState
} from 'react';
import {
  DataGridTableFixedStyled,
  DataGridTableWrapStyled
} from './DataGrid.styled';
import { Search } from '../Search';
import { IUser, IClubsClasses, IReward, IRewardItem } from 'types';
import { API } from 'api';
import { Container, NavigationBar } from 'components';
import { useUsers } from 'context';

interface Category {
  path: string;
  name: string;
}

interface DataGridProps {
  children: React.ReactNode;
  categories: Category[];
  searchUrl: string;
  setSearchData: React.Dispatch<SetStateAction<IUser[]>>
    | React.Dispatch<SetStateAction<IClubsClasses[]>>
    | React.Dispatch<SetStateAction<IReward[]>>
    | React.Dispatch<SetStateAction<IRewardItem[]>>;
  setLoading?: React.Dispatch<SetStateAction<boolean>>;
  searchType?: string;
  setIsLoadMoreShown?: React.Dispatch<SetStateAction<boolean>>;
}

export function DataGrid({
                           children,
                           categories,
                           searchUrl,
                           searchType,
                           setSearchData,
                           setLoading,
                           setIsLoadMoreShown
                         }: DataGridProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const { isPending, setIsPending } = useUsers();
  const SEARCH_TIMEOUT = 300;

  const setLoadMoreOnResults = (results: IUser[] | IClubsClasses[]) => {
    if (results.length >= 10) setIsLoadMoreShown!(true);
  }

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
    setIsPending(true);

    API.get(`${searchUrl}limit=50&search${searchType === 'rewards' ? '=' : '[name]='}${value}`).then(res => {
      if (searchType && searchType === 'members') {
        if (value.trim() === '') {
          API.get(searchUrl).then(res => {
            setLoadMoreOnResults(res.data.members.results);
            setSearchData(res.data.members.results);
          }).catch(e => console.error(e));
        } else {
          setIsLoadMoreShown!(false)
          setSearchData(res.data.members.results);
        }

        setTimeout(() => {
          setIsPending(false);
        }, SEARCH_TIMEOUT);
      } else {
        if (value.trim() === '') {
          API.get(searchUrl).then(res => {
            setLoadMoreOnResults(res.data.results);
            setSearchData(res.data.results);
          }).catch(e => console.error(e));
        } else {
          setIsLoadMoreShown!(false);
          setSearchData(res.data.results);
        }

        setTimeout(() => {
          setIsPending(false);
        }, SEARCH_TIMEOUT);
      }
    }).catch(e => console.error(e));
  };

  const onNavLinkHandler = () => {
    setLoading!(true);
    setSearchQuery('');
  }

  return (
    <Container>
      <NavigationBar items={categories} onNavLinkHandler={onNavLinkHandler}>
        <Search value={searchQuery} onChange={onSearchHandler} />
      </NavigationBar>

      <DataGridTableWrapStyled isPending={isPending}>
        <DataGridTableFixedStyled>
          {children}
        </DataGridTableFixedStyled>
      </DataGridTableWrapStyled>
    </Container>
  );
}
