import React from 'react';
import { Dropdown } from 'components';

const roles = [
  { value: 'student', label: 'Student' },
  { value: 'teacher', label: 'Teacher' },
  { value: 'parent', label: 'Parent' },
];

export function RolesDropdown({ onChange, type }: any) {
  return (
    <Dropdown
      type={type}
      onChange={onChange}
      options={roles} />
  );
}
