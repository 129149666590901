import React, { SetStateAction, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { API } from "api";
import {
  DataGridHeadStyled,
  DataGridRowStyled,
  DataGridTableStyled,
  DataGridTBodyStyled,
  DataGridTHeadStyled,
  DataGridCellStyled,
} from "components/DataGrid";
import { IClubsClasses } from "types";
import { useUsers } from "context";
import { useIsMounted } from "hooks";
import { DeleteIcon } from "assets/icons";
import { ButtonIcon } from "components";
import { getLastUrlSegment } from "utils/helpers";

interface ClubsAndClassesTableProps {
  clubsClasses: IClubsClasses[];
  setClubsClasses: React.Dispatch<SetStateAction<IClubsClasses[]>>;
}

export function ClubAndGroupsTable({
  clubsClasses,
  setClubsClasses,
}: ClubsAndClassesTableProps) {
  const { pathname } = useLocation();
  const { setIsPending } = useUsers();
  const [deleteId, setDeleteId] = useState<number>();
  const [_isMounted] = useIsMounted();
  const type = getLastUrlSegment(pathname) === "clubs" ? "club" : "class";

  const onRemoveClubClassHandler = async (id: number) => {
    const proceed = window.confirm(
      `Are you sure you want to remove the ${type}?`
    );
    if (!proceed) return;

    setIsPending(true);
    setDeleteId(id);
    try {
      await API.delete(`/clubs/${id}`);
      if (_isMounted.current) {
        const updatedList = clubsClasses.filter(
          (clubClass: IClubsClasses) => clubClass.id !== id
        );
        setClubsClasses(updatedList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteId(undefined);
      setIsPending(false);
    }
  };

  return (
    <DataGridTableStyled>
      <DataGridTHeadStyled>
        <DataGridRowStyled>
          {["Name", "Owner", "Members", ""].map((col, index) => (
            <DataGridHeadStyled key={index}>{col}</DataGridHeadStyled>
          ))}
        </DataGridRowStyled>
      </DataGridTHeadStyled>
      <DataGridTBodyStyled>
        {clubsClasses.map((clubClass) => (
          <DataGridRowStyled key={clubClass.id}>
            <DataGridCellStyled>
              <Link to={`${pathname}/${clubClass.id}`}>{clubClass.name}</Link>
            </DataGridCellStyled>
            {type === "club" ? (
              <DataGridCellStyled>{`${clubClass.owner.first_name} ${clubClass.owner.last_name}`}</DataGridCellStyled>
            ) : (
              <DataGridCellStyled>{`${
                clubClass.teacher
                  ? clubClass.teacher.first_name +
                    " " +
                    clubClass.teacher.last_name
                  : "-"
              }`}</DataGridCellStyled>
            )}
            <DataGridCellStyled>{clubClass.members_count}</DataGridCellStyled>
            <DataGridCellStyled align={"right"}>
              <ButtonIcon
                loading={clubClass.id === deleteId}
                onClick={onRemoveClubClassHandler.bind(null, clubClass.id)}
              >
                <DeleteIcon />
              </ButtonIcon>
            </DataGridCellStyled>
          </DataGridRowStyled>
        ))}
      </DataGridTBodyStyled>
    </DataGridTableStyled>
  );
}
