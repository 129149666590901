import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';
import { SpinnerMixin } from 'styles/mixins';

interface RowProps {
  align?: string;
}

interface WrapProps {
  isPending?: boolean;
}

// language=SCSS prefix=*{ suffix=}
export const DataGridTableWrapStyled = styled.div<WrapProps>`
    position: relative;
    
    ${({ isPending }) => isPending && css`
      pointer-events: none;
      
      ${SpinnerMixin};
    `}
`

export const DataGridTableFixedStyled = styled.div`
    overflow-x: auto;
    max-height: calc(100vh - ${rem(350)});
    
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: ${({ theme }) => theme.colors.primary.lightest};
    }
`;

export const DataGridTableStyled = styled.table`
    table-layout: fixed;
    
    th,
    td {
      &:first-child {
        width: 25%;
      }
    }
`;
export const DataGridTHeadStyled = styled.thead``;
export const DataGridTBodyStyled = styled.tbody``;

export const DataGridHeadStyled = styled.th`
    padding: 13px 32px;
    background-color: ${({ theme }) => theme.colors.primary.lightest};
    color: ${({ theme }) => theme.colors.secondary.dark};
    font-size: ${rem(14)};
    line-height: 1.57;
    
    &:first-child {
      text-align: left;
    }
`;

export const DataGridRowStyled = styled.tr<RowProps>`
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
    
     &:hover {
      td {
        background-color: ${({ theme }) => theme.colors.tertiary.light};
        
        &:last-child {
         button {
            svg path {
              fill: #d92d20;
            }
          }
        }
      }
    }
`;

const bgColorMixin = css`
  background-color: rgba(178, 221, 255, 0.5);
`;

export const DataGridCellStyled = styled.td`
    padding: ${rem(13)} ${rem(24)};
    font-weight: 500;
    letter-spacing: 0.75px;
    transition: background-color 0.2s ease-out;
    text-align: ${({ align }) => align === 'right' ? 'right' : 'center'};
    height: ${rem(65)};
    
    &:first-child {
      font-weight: 700;
      text-align: left;
    }
    
    span {
      font-size: ${rem(14)};
      text-transform:capitalize;
    }
    
    button + button {
      margin-left: 1.5rem;
    }
    
    &:nth-child(2) span {
      ${bgColorMixin};
      padding: ${rem(8)} ${rem(28)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ${rem(106)};
      letter-spacing: 0.25px;
      border-radius: 8px;
      color: ${({ theme }) => theme.colors.secondary.dark700};
    }
    
    &:not(:last-child) {
      button:hover {
        ${bgColorMixin};
      }
    }
    
    &:not(:nth-child(2)) span {
      color: #98A2B3;
    }
    
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary.light};
`;

