import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import { CrossIcon } from 'assets/icons';
import {
  Wrapper,
  Header,
  ModalStyled,
  HeaderTitle,
  Content,
  Backdrop,
} from './Modal.styled';
import { ButtonIcon } from 'components';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element;
  headerText: string;
}

export function Modal({ isShown, hide, modalContent, headerText, }: ModalProps) {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isShown) {
      hide();
    }
  };

  useEffect(() => {
    isShown ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  const modal = (
    <React.Fragment>
      <Backdrop />
      <FocusLock>
        <Wrapper aria-modal aria-labelledby={headerText} tabIndex={-1} role="dialog">
          <ModalStyled>
            <Header>
              <HeaderTitle>{headerText}</HeaderTitle>
              <ButtonIcon onClick={hide}><CrossIcon /></ButtonIcon>
            </Header>
            <Content>{modalContent}</Content>
          </ModalStyled>
        </Wrapper>
      </FocusLock>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
}
