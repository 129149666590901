import styled from 'styled-components';
import { rem } from 'styles/theme';

interface SchoolCodeStyledToastProps {
  isVisible: boolean;
}

export const SchoolCodeBtnStyled = styled.button`
    padding: ${rem(2)} ${rem(8)} ${rem(2)} ${rem(6)};
    display: inline-flex;
    align-items: center;
    font-size: ${rem(14)};
    font-weight: 500;
    border-radius: 1rem;
    border: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.dark};
    background-color: ${({ theme }) => theme.colors.primary.lightest};
    line-height: 1.28;
    
    svg {
      margin-right: ${rem(4)};
    }
        
    &:disabled {
      pointer-events: none;
      cursor: default;
    }
`;

export const SchoolCodeToastStyled = styled.div<SchoolCodeStyledToastProps>`
    position: fixed;
    bottom: 2rem;
    text-align: center;
    z-index: 10;
    background: ${({ theme }) => theme.colors.primary.dark};
    left: 50%;
    padding: 4px 14px;
    border-radius: 1rem;
    color: #fff;
    font-weight: 500;
    transform: translateY(${({ isVisible }) => isVisible ? '0' : '200%'});
    transition: transform ease-out 0.2s;
`;
