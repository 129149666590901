import React, { SetStateAction } from 'react';
import { useModal } from 'hooks';
import { AddPointsForm } from './AddPointsForm';
import { Button, Modal } from 'components';
import { IClassMembership, IReward } from 'types';

interface AddPointsProps {
  id: string | number;
  rewards: any;
  setRewards: React.Dispatch<SetStateAction<IReward[]>>;
  setAddId: (value: string | number) => void;
  userName: string;
  total: number;
  classMembership: IClassMembership[];
}

export function AddPoints({ id, total, classMembership, rewards, setRewards, setAddId, userName }: AddPointsProps) {
  const { isShown, toggle } = useModal();
  const content = <AddPointsForm id={id}
                                 total={total}
                                 classMembership={classMembership}
                                 rewards={rewards}
                                 setAddId={setAddId}
                                 setRewards={setRewards} />;

  return (
    <>
      <Button onClick={toggle}
              tertiary
              light
              size={'sm'}>Add</Button>
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText={userName}
        modalContent={content} />
    </>
  )
}
