import React, { useState } from 'react';
import { useUsers } from 'context/UsersContext';

import {
  DataGridHeadStyled,
  DataGridRowStyled,
  DataGridTableStyled,
  DataGridTBodyStyled,
  DataGridTHeadStyled,
  DataGridCellStyled,
  ButtonIcon
} from 'components';
import { DeleteIcon } from 'assets/icons/delete';
import { API } from 'api';
import { IUser } from 'types';
import { useIsMounted } from 'hooks';

export function MembersTable() {
  const { users, setUsers, setIsPending } = useUsers();
  const [deleteId, setDeleteId] = useState<string | number>();
  const [_isMounted] = useIsMounted();

  const onRemoveUserHandler = async (id: string | number) => {
    const proceed = window.confirm('Are you sure you want to delete the member?');
    if (!proceed) return;

    setIsPending(true);
    setDeleteId(id);
    try {
      await API.delete(`/members/${id}`);
      if (_isMounted.current) {
        const updatedList = users.filter((user: IUser) => user.id !== id);
        setUsers(updatedList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsPending(false);
      setDeleteId(undefined);
    }
  };

  return (
    <DataGridTableStyled>
      <DataGridTHeadStyled>
        <DataGridRowStyled>
          {['Name', 'Roles', 'Join Date', ''].map((col, index) =>
            <DataGridHeadStyled key={index}>{col}</DataGridHeadStyled>
          )}
        </DataGridRowStyled>
      </DataGridTHeadStyled>
      <DataGridTBodyStyled>
        {users.map((user) =>
          <DataGridRowStyled key={user.id}>
            <DataGridCellStyled>{`${user.first_name} ${user.last_name}`}</DataGridCellStyled>
            <DataGridCellStyled>
              <span>{user.roles[0]}</span>
            </DataGridCellStyled>
            <DataGridCellStyled>
              {user.user_membership?.created_at!.slice(0, 10)}
            </DataGridCellStyled>
            <DataGridCellStyled align={'right'}>
              <ButtonIcon
                loading={user.id === deleteId}
                onClick={onRemoveUserHandler.bind(null, user.id)}>
                <DeleteIcon />
              </ButtonIcon>
            </DataGridCellStyled>
          </DataGridRowStyled>
        )}
      </DataGridTBodyStyled>
    </DataGridTableStyled>
  );
}
