import React from 'react';
import { InputField, InputGroup, InputLabel, InputGroupText } from './Input.styled';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  secondary?: boolean;
  text?: string;
}

export function Input({ id, label, ...rest }: Props) {
  return (
    <InputGroup>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <InputField id={id} {...rest} />
    </InputGroup>
  );
}

export function InputText({ id, text, label, ...rest }: Props) {
  return (
    <InputGroup>
      <InputLabel htmlFor={id}>{label}</InputLabel>

      <InputGroupText>
        <span>{text}</span>
        <InputField id={id} {...rest} />
      </InputGroupText>
    </InputGroup>
  );
}
