import styled from 'styled-components';
import { rem } from 'styles/theme';

// language=SCSS prefix=*{ suffix=}
export const UploadButtonIconStyled = styled.div`
    background-color: #d1e9ff;
    width: 40px;
    height: 40px;
    border: 6px solid #F5FAFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      padding: 1px;
    }
`;

export const AddNewRewordFormUploadStyled = styled.div`
    background-color: #fff;
    border-radius: ${rem(8)};
    min-height: ${rem(104)};
    padding: ${rem(14)} ${rem(14)};
    width: 100%;
    position: relative;
    font-size: ${rem(14)};
    line-height: 1.42;
    
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 0;
      z-index: -1;
      left: 0;
      width: 100%;
      height: 100%;
      color: transparent;
    }
    
    label {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary.dark};
      width: ${rem(100)};
      display: block;
      margin: auto;
      text-align: center;
      position: relative;
      
      ${UploadButtonIconStyled} {
        margin: 0 auto ${rem(10)};
      }
      
      &:focus-within {
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary.lightDark};
      }
    }
`;

export const RewardUploadFileInfoStyled = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    ${UploadButtonIconStyled} {
      margin-right: ${rem(12)};
    }
    
    & > div:first-of-type {
      display: inline-flex;
    }
`;

export const RewardUploadFileInfoNameStyled = styled.div`
    color: ${({ theme }) => theme.colors.secondary.dark700};
    max-width: ${rem(300)};
`;

export const RewardUploadFileInfoSizeStyled = styled.div`
    color: ${({ theme }) => theme.colors.secondary.darkish};
    font-weight: 400;
`;

export const RewardUploadFileInfoInProgressStyled = styled.span`
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary.dark};
`;

export const RewardUploadFileInfoRemoveStyled = styled.button`
    background-color: transparent;
    border: 0;
    width: ${rem(18)};
    height: ${rem(18)};
    display: inline-flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    cursor: pointer;
`;

export const RewardUploadFileInfoProgressStyled = styled.div`
    display: inline-flex;
    align-items: center;
    margin-top: ${rem(8)};
`;

export const RewardUploadFileInfoProgressBarStyled = styled.progress`
    height: ${rem(8)};
    background-color: transparent;
    margin-right: ${rem(12)};
    width: ${rem(304)};
    
    &::-webkit-progress-bar {
      background-color: ${({ theme }) => theme.colors.primary.light};
      border-radius: 4px;
     
    }
    
    &::-webkit-progress-value {
    border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }
    
    &::-moz-progress-bar {
    
    }
`;
