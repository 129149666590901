import React from 'react';
import { Link } from 'react-router-dom'
import { SidebarStyled, LogoStyled } from './Sidebar.styled';
import { Navigation } from '../Navigation';
import { ReactComponent as LogoIcon } from 'assets/notis-logo.svg';

export function Sidebar() {
  return (
    <SidebarStyled>
      <LogoStyled>
        <Link to="/">
          <LogoIcon />
        </Link>
      </LogoStyled>

      <Navigation />
    </SidebarStyled>
  );
}
