const renderTimeShort = (date: Date) => {
  return date.toLocaleTimeString('en-us', { timeStyle: 'short' })
}

export const formatToLocaleDate = (dateStr: string, type: any = 'short') => {
  let dateOrigin = new Date(dateStr);
  let dateCurrent = new Date(new Date().setHours(23, 59, 59, 999));
  let timeDiff = dateOrigin.getTime() - dateCurrent.getTime();
  let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (diffDays === -1) {
    return `Yesterday at ${renderTimeShort(dateOrigin)}`
  }

  if (diffDays === 0) {
    return `Today at ${renderTimeShort(dateOrigin)}`
  }

  const month = dateOrigin.toLocaleString('default', { month: type })
  const year = dateOrigin.getFullYear();
  const day = dateOrigin.toLocaleDateString('en-us', { day: '2-digit' });
  const weekDay = dateOrigin.toLocaleDateString('en-US', { weekday: 'short' });
  const dayFull = type === 'short' ? `${weekDay}, ` : '';

  return `${dayFull}${month} ${day}, ${year}`;
}

export const formatToLocaleTime = (dateStr: string) => {
  return renderTimeShort(new Date(dateStr));
}

export const renderDate = (date: string) => {
  return date ? date.slice(0, 10) : '-';
};
