import React, { SetStateAction, useState } from "react";
import {
  DataGridHeadStyled,
  DataGridRowStyled,
  DataGridTableStyled,
  DataGridTBodyStyled,
  DataGridTHeadStyled,
  DataGridCellStyled,
} from "components";

import { IReward } from "types";
import { AddPoints, RemovePoints, RewardPointNotif } from "./components";
import { CheckIcon, CrossIcon } from "assets/icons";

interface RewardsProps {
  rewards: IReward[];
  setRewards: React.Dispatch<SetStateAction<IReward[]>>;
}

export function PointsTable({ rewards, setRewards }: RewardsProps) {
  const [addId, setAddId] = useState<string | number>("");
  const [removeId, setRemoveId] = useState<string | number>();

  return (
    <DataGridTableStyled>
      <DataGridTHeadStyled>
        <DataGridRowStyled>
          {["Name", "Reward Points", "Class", "Actions"].map((col, index) => (
            <DataGridHeadStyled key={index}>{col}</DataGridHeadStyled>
          ))}
        </DataGridRowStyled>
      </DataGridTHeadStyled>

      <DataGridTBodyStyled>
        {rewards.map((reward: IReward) => (
          <DataGridRowStyled key={reward.user.id}>
            <DataGridCellStyled>{`${reward.user.first_name} ${reward.user.last_name}`}</DataGridCellStyled>
            <DataGridCellStyled>{reward.points.total}</DataGridCellStyled>
            <DataGridCellStyled>
              {reward.user.class_memberships.length &&
              reward.user.class_memberships[0].class_id
                ? reward.user.class_memberships[0].class_id
                : "-"}
            </DataGridCellStyled>
            <DataGridCellStyled>
              {addId === reward.user.id ? (
                <RewardPointNotif>
                  <CheckIcon />
                  Added
                </RewardPointNotif>
              ) : removeId === reward.user.id ? (
                <RewardPointNotif darkish>
                  <CrossIcon />
                  Removed
                </RewardPointNotif>
              ) : (
                <>
                  <AddPoints
                    id={reward.user.id}
                    total={reward.points.total}
                    classMembership={reward.user.class_memberships}
                    setAddId={setAddId}
                    rewards={rewards}
                    userName={`${reward.user.first_name} ${reward.user.last_name}`}
                    setRewards={setRewards}
                  />
                  <RemovePoints
                    id={reward.user.id}
                    total={reward.points.total}
                    classMembership={reward.user.class_memberships}
                    setRemoveId={setRemoveId}
                    userName={`${reward.user.first_name} ${reward.user.last_name}`}
                    rewards={rewards}
                    setRewards={setRewards}
                  />
                </>
              )}
            </DataGridCellStyled>
          </DataGridRowStyled>
        ))}
      </DataGridTBodyStyled>
    </DataGridTableStyled>
  );
}
