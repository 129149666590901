import React, { SetStateAction, useState } from 'react';
import { API } from 'api';
import { FeedListStyled, FeedItemStyled, FeedListWrapStyled } from './FeedList.styled';
import { Feed } from '../Feed';
import { useIsMounted } from 'hooks';
import { IFeed } from 'types';
import { useUsers } from 'context';

interface FeedListProps {
  feedList: IFeed[];
  setFeedList: React.Dispatch<SetStateAction<IFeed[]>>;
}

export function FeedList({ feedList, setFeedList }: FeedListProps) {
  const { isPending, setIsPending } = useUsers();
  const [deleteId, setDeleteId] = useState('');
  const [_isMounted] = useIsMounted();


  const onFeedRemoveHandler = async (id: string) => {
    const proceed = window.confirm('Are you sure you want to delete the feed?');
    if (!proceed) return;

    setIsPending(true);
    setDeleteId(id);

    try {
      await API.delete(`/notises/${id}`);
      if (_isMounted.current) {
        setFeedList(feedList.filter((feed) => feed.id !== id));
      }
    } catch (e) {
      console.error(e);
      alert('Unable to delete.');
    } finally {
      setIsPending(false)
      setDeleteId('');
    }
  }

  return (
    <FeedListWrapStyled isPending={isPending}>
      <FeedListStyled>
        {feedList.map((feed: IFeed) =>
          <FeedItemStyled key={feed.id}>
            <Feed
              deleteId={deleteId}
              onFeedRemoveHandler={onFeedRemoveHandler}
              feed={feed} />
          </FeedItemStyled>
        )}
      </FeedListStyled>
    </FeedListWrapStyled>
  );
}
