import React, { SetStateAction, useState } from "react";
import {
  FormSubmit,
  Button,
  Input,
  InputGroup,
  InputLabel,
  InputGroupSub,
  FormErrorMessageStyled,
} from "components";
import { useUsers } from "context/UsersContext";
import { API } from "api";
import { GradesDropdown, RolesDropdown } from "../../components";

interface IProps {
  hide: () => void;
  setCleanup: React.Dispatch<SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

interface InputProps {
  first_name: string;
  last_name: string;
  email: string;
  roles: string[];
  class_id: number | undefined;
}

interface ErrorProps {
  first_name: string;
  last_name: string;
  email: string;
  roles: string;
  class_id: string;
}

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  roles: [],
  class_id: undefined,
};

export function AddUserForm({ hide, setCleanup, setIsLoading }: IProps) {
  const { query, setQuery } = useUsers();
  const [isPending, setIsPending] = useState(false);
  const [state, setState] = useState<InputProps>(initialState);
  const [errorMessage, setErrorMessage] = useState<ErrorProps | null>(null);

  const isFormValid =
    state.first_name && state.last_name && state.email && state.roles.length;

  const onSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsPending(true);

    try {
      setErrorMessage((prevState) => null);
      const response = await API.post("/members", state);
      if (response.status === 200) {
        hide();
        setIsLoading(true);
        setQuery(!query);
        setCleanup(true);
      }
    } catch (error: any) {
      setIsPending(false);
      console.error(error);

      const updatedError = {
        first_name: error.response.data.errors.first_name?.msg,
        last_name: error.response.data.errors.last_name?.msg,
        roles: error.response.data.errors.roles?.msg,
        email: error.response.data.errors.email?.msg,
        class_id: error.response.data.errors.class_id?.msg,
      };

      setErrorMessage(updatedError);
    }
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value, name } = e.target;
    setState({
      ...state,
      [name]:
        name === "class_id"
          ? value === ""
            ? undefined
            : parseInt(value)
          : value,
    });
  };

  const onRolesSelect = (e: any) => {
    if (e.value !== "student") {
      setState({ ...state, roles: [e.value], class_id: undefined });
    } else {
      setState({ ...state, roles: [e.value] });
    }
  };

  const onGradesSelect = (e: any) => {
    setState({ ...state, 'class_id': e.value });
  }

  return (
    <form>
      {errorMessage &&
        Object.entries(errorMessage).map(([key, value]) => (
          <FormErrorMessageStyled key={key}>{value}</FormErrorMessageStyled>
        ))}
      <Input
        onChange={onChangeHandler}
        label={"Name*"}
        id={"first_name"}
        name={"first_name"}
        type={"text"}
      />
      <Input
        onChange={onChangeHandler}
        label={"Last name*"}
        id={"last_name"}
        name={"last_name"}
        type={"text"}
      />
      <Input
        onChange={onChangeHandler}
        label={"Email*"}
        id={"email"}
        name={"email"}
        type={"email"}
      />

      <hr />

      <InputGroup nojustify>
        <InputGroupSub>
          <InputLabel htmlFor={"roles"}>Role*</InputLabel>
          <RolesDropdown type={"roles"} onChange={onRolesSelect} />
        </InputGroupSub>

        {state.roles.includes('student') &&
        <InputGroupSub>
          <InputLabel htmlFor={'class_id'}>Grade</InputLabel>
          <GradesDropdown
              name={'class_id'}
              id={'class_id'}
              onChange={onGradesSelect}
          />
        </InputGroupSub>
        }
      </InputGroup>

      <FormSubmit>
        <Button
          disabled={!isFormValid}
          size={"md"}
          primary
          loading={isPending}
          onClick={onSubmit}
        >
          <span>Add</span>
        </Button>
      </FormSubmit>
    </form>
  );
}
