import React, { SetStateAction } from 'react';
import { useModal } from 'hooks';
import { Button, Modal } from 'components';
import { RemovePointsForm } from './RemovePointsForm';
import { IClassMembership, IReward } from 'types';

interface AddPointsProps {
  id: string | number;
  rewards: any;
  setRewards: React.Dispatch<SetStateAction<IReward[]>>;
  setRemoveId: (value: string | number) => void;
  userName: string;
  total: number;
  classMembership: IClassMembership[];
}

export function RemovePoints({ id, total, classMembership, rewards, setRewards, setRemoveId, userName }: AddPointsProps) {
  const { isShown, toggle } = useModal();
  const content = <RemovePointsForm id={id}
                                    total={total}
                                    classMembership={classMembership}
                                    rewards={rewards}
                                    setRemoveId={setRemoveId}
                                    setRewards={setRewards} />;

  return (
    <>
      <Button onClick={toggle}
              tertiary
              size={'sm'}>Remove</Button>

      <Modal
        isShown={isShown}
        hide={toggle}
        headerText={userName}
        modalContent={content} />
    </>
  )
}
