import React, { ReactNode } from 'react';
import { ContentStyled } from './Content.styled';
import { useUsers } from 'context/UsersContext';

interface Props {
  children: ReactNode;
}

export function Content({ children }: Props) {
  const { isUploading } = useUsers();
  return <ContentStyled isUploading={isUploading}>{children}</ContentStyled>;
}
