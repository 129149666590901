import React from 'react';
import { Dropdown } from 'components';

const grades = Array.from(new Array(12).keys()).map((index) => {
  const item = index + 1;
  return { value: item, label: item }
});

const reversedGrades = [...grades].reverse();

export function GradesDropdown({ onChange }: any) {
  return (
    <Dropdown
      onChange={onChange}
      options={reversedGrades} />
  );
}
