import { IFeed, ISchoolDetails } from 'types';

const ASSETS_ROUTE = 'assets/images';
const BASE_IMAGE_PATH = `${process.env.REACT_APP_API_ENDPOINT}/${ASSETS_ROUTE}`;

export const getFeedMainImagePath = (feed: IFeed) => {
  if (feed && feed.id && feed.main_image) {
    return `${BASE_IMAGE_PATH}/${feed.id}/${feed.main_image}`
  } else {
    return '';
  }
};
export const getFeedImagePath = (feed: IFeed) => {
  if (feed && feed.id && feed.image_name && feed.image_name[0]) {
    return `${BASE_IMAGE_PATH}/${feed.id}/${feed.image_name[0]}`
  } else {
    return '';
  }
};

export const getFeedAuthorImage = (feed: IFeed) => {
  if (feed && feed.user && feed.user.profile_image) {
    return `${BASE_IMAGE_PATH}/${feed.user.id}/${feed.user.profile_image}`
  } else {
    return '';
  }
};

export const getSchoolLogo = (school: ISchoolDetails) => {
  if (school && school.images && school.images[0]) {
    return `${BASE_IMAGE_PATH}/school/${school.images[0]}`;
  } else {
    return '';
  }
}


