import styled from 'styled-components';
import { SpinnerLoaderMixin } from 'styles/mixins';

export const PreLoader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.tertiary.light2};
    z-index: 100;
    
    ${SpinnerLoaderMixin};
`;
