import axios from 'axios';
import { getAuthSession } from './services';

const { token_id, school_id }: any = getAuthSession() || {};

export const setAuthToken = (token: string) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

setAuthToken(token_id);

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Expires'] = '0';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.responseType = 'json';


export const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}/v3/admin/schools/${school_id}`
});
