import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';

const radiusMixin = css`
    border-radius: ${rem(8)};   
`;

export const AdsCardStyled = styled.div`
    ${radiusMixin};
    background-color: ${({ theme }) => theme.colors.tertiary.lightest};
    padding: 1rem;
`;

export const AdsCardWrapStyled = styled.div`
    ${radiusMixin};
    background-color: ${({ theme }) => theme.colors.tertiary.light};
    display: flex;
`;

export const AdsCardFigureStyled = styled.figure`
    flex-shrink: 0;
`;

export const AdsCardImageStyled = styled.img`
   border-top-left-radius: ${rem(8)};
   border-bottom-left-radius: ${rem(8)};
`;

export const AdsCardBodyStyled = styled.div`
    flex-grow: 1;
    position: relative;
    padding: ${rem(24)} 1rem 1rem ${rem(24)};
`;

const sharedSizeLineHeight = css`
    line-height: 1.2;
    font-size: 1rem;
`;

export const AdsCardLabelStyled = styled.div`
    ${sharedSizeLineHeight};
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.colors.secondary.darkish};
    margin-bottom: ${rem(8)};
`;

export const AdsCardInfoStyled = styled.div`
    ${sharedSizeLineHeight};
    margin-bottom: ${rem(24)};
    color: #101828;
`;

export const AdsCardBulletStyled = styled.span`
    color:  ${({ theme }) => theme.colors.primary.light};
    margin: 0 ${rem(8)};
`

export const AdsCardDateStyled = styled.span`
    opacity: 0.6;
`;

export const AdsCardLinkStyled = styled.a`
    ${sharedSizeLineHeight};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primary.dark};
`;

export const AdsCardControlsStyled = styled.div`
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    
    button + button {
      margin-left: ${rem(8)};
    }
`;
