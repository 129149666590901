import styled from 'styled-components';
import { rem } from 'styles/theme';

// language=SCSS prefix=*{ suffix=}
export const DropdownStyled = styled.div`
    width: ${rem(102)};

    .css-9gakcf-option,
    .css-1n7v3ny-option {
      background-color: ${({ theme }) => theme.colors.primary.light};
      color: #404040;
      cursor: pointer;
    }
    
    .css-26l3qy-menu,
    .css-1s2u09g-control,
    .css-9gakcf-option,
    .css-1n7v3ny-option {
      border-radius: ${rem(8)};
    }

    .css-qc6sy-singleValue {
      color: ${({ theme }) => theme.colors.secondary.dark700};;
    }
    
    .css-14el2xx-placeholder {
      color: #000;
      text-align: center;
    }
    
    .css-4ljt47-MenuList div,
    .css-14el2xx-placeholder,
    .css-qc6sy-singleValue {
      font-size: ${rem(14)};
      line-height: 1.57;
    }
    
    .css-319lph-ValueContainer,
    .css-4ljt47-MenuList {
      padding: 0;

      div {
        padding-left: 1rem;
        font-weight: 500;
      }
    }

    .css-26l3qy-menu {
      margin-top: 2px;
      border: 1px solid rgba(58, 152, 208, 0.1);
      box-shadow: 0 4px 10px rgba(64, 64, 64, 0.15);
    }

    .css-14el2xx-placeholder {
      margin: 0;
      padding-left: ${rem(26)};
    }


    .css-1okebmr-indicatorSeparator {
      display: none;
    }
`;
