import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  ClubsAndClasses,
  Members,
  LiveFeed,
  NotFound,
  Settings,
  SponsoredAds,
  UserDetails,
  Users,
  Rewards
} from './pages';

const routes = (
  <>
    <Routes>
      <Route path="/" element={<Users />} />
      <Route index element={<Navigate to="users/all" replace />} />
      <Route path="/users">
        <Route index element={<Navigate to="all" replace />} />
        {['all', 'teachers', 'students', 'parents', 'moderators'].map((path) =>
          <Route path={path} element={<Users />} key={path} />
        )}
        <Route path="detail/:id" element={<UserDetails />} />
      </Route>

      <Route path="/clubs-and-classes">
        <Route index element={<Navigate to="clubs" replace />} />
        <Route path="clubs" element={<ClubsAndClasses />} />
        <Route path="classes" element={<ClubsAndClasses />} />
        {['clubs', 'classes'].map((path) =>
          <Route path={`${path}/:id`} key={path}>
            <Route index element={<Navigate to="all" replace />} />
            {['all', 'students', 'parents'].map((path) =>
              <Route path={path} element={<Members />} key={path} />
            )}
          </Route>
        )}
      </Route>

      <Route path="/rewards">
        <Route index element={<Navigate to="points" replace />} />
        <Route path="points" element={<Rewards />} />
        <Route path="reward-items" element={<Rewards />} />
      </Route>

      <Route path="/sponsored-ads" element={<SponsoredAds />} />
      <Route path="/live-notis-feed" element={<LiveFeed />} />

      <Route path="/settings">
        <Route index element={<Navigate to="school-details" replace />} />
        <Route path="school-details" element={<Settings />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
);

export default routes;
