import styled from 'styled-components';
import { rem } from 'styles/theme';

interface Props {
  width: number;
  height: number;
  radius?: number;
}

export const AvatarStyled = styled.div<Props>`  
    width: ${({ width }) => width ? rem(width) : rem(64)};
    height: ${({ height }) => height ? rem(height) : rem(64)};
    overflow: hidden;
    border-radius: ${({ radius }) => radius ? rem(radius) : '50%'};
    background-color: ${({ theme }) => theme.colors.primary.darkest};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(24)};
    font-weight: bold;
    line-height: 1;
`;

export const AvatarImgStyled = styled.img`  
    object-fit: cover;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: ${rem(12)};
`;
