import styled from 'styled-components';
import { rem } from 'styles/theme';

interface BulletStyledProps {
  primary?: boolean;
}

export const BulletStyled = styled.span<BulletStyledProps>`
    color: ${(props) => props.primary ? props.theme.colors.primary.light : '#d0d5dd'};
    margin: 0 ${rem(8)};
`;
