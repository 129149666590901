import React, { ReactNode } from 'react';
import { PageControlsStyled } from './PageControls.styled';

interface Props {
  children: ReactNode;
  marginR?: number
}

export function PageControls({ children, marginR }: Props) {
  return <PageControlsStyled marginR={marginR}>{children}</PageControlsStyled>;
}
