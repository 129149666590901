import { useEffect, useRef } from 'react';

export const useIsMounted = () => {
  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    }
  }, []);

  return [_isMounted];
}
