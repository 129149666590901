import styled from 'styled-components';
import { rem } from 'styles/theme';
import { InputField, InputGroupText, InputLabel } from 'components';
import { SpinnerMixin } from 'styles/mixins';

interface SettingsStyledProps {
  isPending: boolean;
}

export const SettingsStyled = styled.div<SettingsStyledProps>`
    border-top: 1px solid ${({ theme }) => theme.colors.secondary.light};
    padding: ${rem(24)} 0 0 ${rem(24)};
    position: relative;
    
    ${({ isPending }) => isPending && SpinnerMixin};
`;

export const SettingsRowStyled = styled.div`
    padding: ${rem(24)} 0;
    
    &:first-of-type {
      padding-bottom: 0;
    }
    
    &:not(:first-of-type):not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.secondary.light};
    }
`;

export const SettingsItemStyled = styled.div`
    max-width: ${rem(824)};
    margin-right: ${rem(24)};
    
    ${InputLabel} {
      color: ${({ theme }) => theme.colors.secondary.dark700};
      font-size: ${rem(14)};
      line-height: 1.42;
      letter-spacing: 0.005em;
      width: 34%;
      margin-right: 2rem;
      flex: 0 0 auto;
      
      span {
        color: ${({ theme }) => theme.colors.secondary.darkish};
      }
   }
    
    ${InputGroupText},
    ${InputField} {
      max-width: ${rem(512)};
      height: ${rem(44)};
    }
    
    img {
      cursor: pointer;
    }
`;
