import { useEffect, useState } from 'react';
import { parseSessionStorage, queryString } from 'utils/helpers';
import { CONST_AUTH } from 'utils/constants';

export const useAuth = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const tokenIdQuery = queryString(CONST_AUTH.TOKEN_ID);
    const schoolIdQuery = queryString(CONST_AUTH.SCHOOL_ID);

    if (tokenIdQuery && !sessionStorage.getItem(CONST_AUTH.SCHOOL_CREDS)) {
      const schoolCreds = {
        token_id: tokenIdQuery,
        school_id: schoolIdQuery,
      }

      sessionStorage.setItem(CONST_AUTH.SCHOOL_CREDS, JSON.stringify(schoolCreds));
    }

    if (sessionStorage.getItem(CONST_AUTH.SCHOOL_CREDS)) {
      const schoolCreds = parseSessionStorage(sessionStorage.getItem(CONST_AUTH.SCHOOL_CREDS))
      if (schoolCreds.token_id && schoolCreds.school_id) {
        setIsAuthorized(true);
      }
    }
  }, []);

  return [isAuthorized];
}
