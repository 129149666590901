import React, { MouseEventHandler, ReactNode } from 'react';
import {
  ButtonStyled,
  ButtonIconStyled,
  ButtonUploadStyled,
  ButtonUpdateStyled
} from './Button.styled';

interface Props {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: string;
  disabled?: boolean;
  bold?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  light?: boolean;
  loading?: boolean;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}

export function Button({
                         children,
                         onClick,
                         secondary,
                         tertiary,
                         primary,
                         light,
                         size,
                         bold,
                         loading,
                         disabled
                       }: Props) {
  return <ButtonStyled
    disabled={disabled}
    bold={bold}
    size={size}
    primary={primary}
    light={light}
    loading={loading ? loading.toString() : undefined}
    secondary={secondary}
    tertiary={tertiary}
    onClick={onClick}>{children}
  </ButtonStyled>
}

export function ButtonUpload({ children, size }: Props) {
  return <ButtonUploadStyled
    size={size}
  >{children}
  </ButtonUploadStyled>
}

export function ButtonUpdate({ children, size, onClick }: Props) {
  return <ButtonUpdateStyled
    size={size}
    onClick={onClick}
  >{children}
  </ButtonUpdateStyled>
}

export function ButtonIcon({ children, onClick, loading, onMouseEnter, onMouseLeave }: Props) {
  return <ButtonIconStyled
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    loading={loading ? loading.toString() : undefined}
    onClick={onClick}>{children}</ButtonIconStyled>
}
