import React from 'react';
import { SearchStyled, SearchFieldStyled } from './Search.styled';
import { SearchIcon } from 'assets/icons';

interface SearchProps {
  onChange: any;
  value: string;
}

export function Search({ onChange, value }: SearchProps) {
  return (
    <SearchStyled>
      <SearchIcon />
      <SearchFieldStyled
        minLength={1}
        value={value}
        type={'search'}
        debounceTimeout={320}
        onChange={onChange}
      />
    </SearchStyled>

  );
}
