import React, { useState } from 'react';
import { Button, FormSubmit } from 'components';
import { IClassMembership, IReward } from 'types';
import { RewardPointsFields } from './RewardPointsFields';
import { API } from 'api';

interface IProps {
  id: string | number;
  total: number;
  classMembership: IClassMembership[];
  rewards: any;
  setRewards: any;
  setAddId: (value: string | number) => void;
}

export function AddPointsForm({ id, total, classMembership, rewards, setRewards, setAddId }: IProps) {
  const [isPending, setIsPending] = useState(false);
  const [points, setPoints] = useState<number | undefined>(undefined);
  const isFormValid = points;
  const apiData = {
    points: points
  }

  const onAddPointHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const proceed = window.confirm('Are you sure you want to add reward point(s)?');
    if (!proceed) return;

    setIsPending(true);
    const updatedRewards = rewards.map((reward: IReward) => {
      if (reward.user.id === id) {
        return {
          ...reward,
          points: {
            ...reward.points,
            total: reward.points.total += points!
          }
        }
      }

      return reward;
    });

    try {
      await API.post(`/reward/leaderboard/${id}/add-points`, apiData);
      setRewards(updatedRewards);
      setAddId(id);

      setTimeout(() => {
        setAddId('');
      }, 800);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <form>
      <RewardPointsFields
        total={total}
        classMembership={classMembership}
        setPoints={setPoints} />

      <FormSubmit>
        <Button disabled={!isFormValid}
                size={'md'}
                primary
                loading={isPending}
                onClick={onAddPointHandler}>
          <span>Add</span>
        </Button>
      </FormSubmit>
    </form>
  )
}
