import React from 'react';
import {
  AdsCardBodyStyled,
  AdsCardBulletStyled,
  AdsCardControlsStyled,
  AdsCardDateStyled,
  AdsCardFigureStyled,
  AdsCardImageStyled,
  AdsCardInfoStyled,
  AdsCardLabelStyled,
  AdsCardLinkStyled,
  AdsCardStyled,
  AdsCardWrapStyled
} from './AdsCard.styled';
import { formatToLocaleDate } from 'utils/helpers';
import { Button } from 'components';
import { ISponsoredAds } from 'types';

interface AdsCardProps {
  card: ISponsoredAds;
  approveId: number | undefined;
  removeId: number | undefined;
  onDeleteHandler: (value: number) => void;
  onApproveHandler: (value: number) => void;
}

export function AdsCard({
                          card,
                          approveId,
                          removeId,
                          onDeleteHandler,
                          onApproveHandler
                        }: AdsCardProps) {
  return (
    <AdsCardStyled>
      <AdsCardWrapStyled>
        <AdsCardFigureStyled>
          <AdsCardImageStyled src={card.image} />
        </AdsCardFigureStyled>

        <AdsCardBodyStyled>
          <AdsCardLabelStyled>Submitted by</AdsCardLabelStyled>
          <AdsCardInfoStyled>
            <span>{card.author}</span>
            <AdsCardBulletStyled>&bull;</AdsCardBulletStyled>
            <AdsCardDateStyled>{formatToLocaleDate(card.created_at)}</AdsCardDateStyled>
          </AdsCardInfoStyled>

          <AdsCardLabelStyled>Link</AdsCardLabelStyled>
          <AdsCardLinkStyled
            href={card.url}
            target={'_blank'}
            rel={'noopener noreferrer'}>{card.url}
          </AdsCardLinkStyled>

          <AdsCardControlsStyled>
            <Button
              loading={removeId === card.id}
              onClick={onDeleteHandler.bind(null, card.id)}
              secondary
              size={'sm'}><span>Delete</span></Button>

            {!card.approved &&
            <Button
                loading={approveId === card.id}
                onClick={onApproveHandler.bind(null, card.id)}
                primary
                size={'sm'}><span>Approve</span></Button>
            }
          </AdsCardControlsStyled>

        </AdsCardBodyStyled>
      </AdsCardWrapStyled>
    </AdsCardStyled>
  )
}
