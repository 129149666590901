import styled, { css } from 'styled-components';
import { rem } from 'styles/theme';

interface TooltipStyledProps {
  direction: string;
}

const bgMixin = css`
  background-color: ${({ theme }) => theme.colors.secondary.light};
`;
// language=SCSS prefix=*{ suffix=}
export const TooltipStyled = styled.div<TooltipStyledProps>`
    ${bgMixin};
    position: absolute;
    border-radius: ${rem(6)};
    padding: ${rem(8)};
    font-size: ${rem(12)};
    line-height: 1.16;
    color:  ${({ theme }) => theme.colors.secondary.dark700};
    top: 114%;
    z-index: 1;
   
   ${({ direction }) => direction === 'right' && css`
      left: ${rem(14)};
    `}
    
    ${({ direction }) => direction === 'left' && css`
       right: ${rem(14)};
    `}
    
    &::before {
      ${bgMixin};
      content: '';
      position: absolute;
      border-radius: ${rem(0.5)};
      top: -${rem(8)};
      right: 0;
      margin: 0 auto;
      left: 0;
      width: ${rem(12)};
      height: ${rem(9)};
      transform-origin: 0 100%;        
      transform: rotate(45deg);

    }
`;
