import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { All, TeachersParents, ModeratorsStudents } from './index';
import { useUsers } from 'context/UsersContext';
import {
  DataGridHeadStyled,
  DataGridRowStyled, DataGridTableStyled,
  DataGridTBodyStyled,
  DataGridTHeadStyled
} from 'components/DataGrid';
import { getLastUrlSegment, isUserModerator } from 'utils/helpers';
import { API } from 'api';
import { IUser } from 'types';
import { useIsMounted } from 'hooks';

interface ObjectLiteral {
  [key: string | number]: string[]
}

const SCHOOL_OWNER = 'school_owner';
const TABLE_HEAD = {
  NAME: 'Name',
  ROLES: 'Role',
  DATE: 'Sign Up Date',
  CLASS: 'Class',
  MODERATOR: 'Moderator',
}

const categoryColumns: ObjectLiteral = {
  all: [TABLE_HEAD.NAME, TABLE_HEAD.ROLES, TABLE_HEAD.DATE, TABLE_HEAD.CLASS, TABLE_HEAD.MODERATOR, ''],
  teachers: [TABLE_HEAD.NAME, TABLE_HEAD.ROLES, TABLE_HEAD.DATE, TABLE_HEAD.MODERATOR, ''],
  students: [TABLE_HEAD.NAME, TABLE_HEAD.ROLES, TABLE_HEAD.DATE, TABLE_HEAD.CLASS, TABLE_HEAD.MODERATOR, ''],
  parents: [TABLE_HEAD.NAME, TABLE_HEAD.ROLES, TABLE_HEAD.DATE, TABLE_HEAD.MODERATOR, ''],
  moderators: [TABLE_HEAD.NAME, TABLE_HEAD.ROLES, TABLE_HEAD.DATE, TABLE_HEAD.CLASS, TABLE_HEAD.MODERATOR, ''],
}

export function UserTable({ category }: any) {
  const { users, setUsers, setIsPending } = useUsers();
  const [deleteId, setDeleteId] = useState('');
  const columns: string[] = categoryColumns[category!];
  const [_isMounted] = useIsMounted();
  const { pathname } = useLocation();

  const onRemoveUserHandler = async (id: string) => {
    const proceed = window.confirm('Are you sure you want to remove the user?');
    if (!proceed) return;

    setIsPending(true);
    setDeleteId(id);
    try {
      await API.delete(`/members/${id}`);
      if (_isMounted.current) {
        const updatedList = users.filter((user: IUser) => user.id !== id);
        setUsers(updatedList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteId('')
      setIsPending(false);
    }
  };

  const onToggleRoleHandler = async (id: string | number) => {
    setIsPending(true);
    const updatedUsers = users.map((user) => {
      if (user.id === id) {
        return {
          ...user,
          roles: !isUserModerator(user.roles)
            ? [...user.roles, SCHOOL_OWNER]
            : user.roles.filter(item => item !== SCHOOL_OWNER)
        };
      }

      return user;
    });

    try {
      const currentUser = updatedUsers.find(user => user.id === id);
      await API.put(`/members/${id}/roles`, { roles: currentUser?.roles });
      setUsers(updatedUsers);

      if (getLastUrlSegment(pathname) === 'moderators') {
        const timeout = setTimeout(() => {
          setUsers(prevState => updatedUsers.filter(user => user.id !== id));
          setIsPending(false);
          clearTimeout(timeout);
        }, 800);
      } else {
        setIsPending(false);
      }
    } catch (e) {
      console.error(e)
    }
  };

  const sharedProps = { onRemoveUserHandler, onToggleRoleHandler, deleteId };

  return (
    <DataGridTableStyled>
      <DataGridTHeadStyled>
        <DataGridRowStyled>
          {columns.map((col, index) =>
            <DataGridHeadStyled key={index}>{col}</DataGridHeadStyled>
          )}
        </DataGridRowStyled>
      </DataGridTHeadStyled>

      <DataGridTBodyStyled>
        {category === 'all' &&
        <All
            onRemoveUserHandler={onRemoveUserHandler}
            deleteId={deleteId}
            onToggleRoleHandler={onToggleRoleHandler}
        />}
        {['parents', 'teachers'].includes(category) && <TeachersParents {...sharedProps} />}
        {['moderators', 'students'].includes(category) && <ModeratorsStudents {...sharedProps} />}
      </DataGridTBodyStyled>
    </DataGridTableStyled>
  );
}
