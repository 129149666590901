import React from 'react';
import { RewardPointNotifStyled } from './RewardPointNotif.styled';

interface RewardPointNotifProps {
  children: React.ReactNode;
  darkish?: boolean;
}

export function RewardPointNotif({ children, darkish }: RewardPointNotifProps) {
  return <RewardPointNotifStyled darkish={darkish}>{children}</RewardPointNotifStyled>
}
