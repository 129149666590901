import React, { useEffect, useState } from 'react';
import { LoadMore, PageHeader, SkeletonFeed } from 'components';
import { API } from 'api';
import { CONST_FETCH_PAGE_ERROR } from 'utils/constants';
import { FeedList } from './components';
import { IFeed } from 'types';
import { useUsers } from 'context';

export function LiveFeed() {
  const { setIsPending, setIsPaginating } = useUsers();
  const [feedList, setFeedList] = useState<IFeed[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoadMoreShown, setIsLoadMoreShown] = useState(false);

  useEffect(() => {
    let isApiSubscribed = true;
    const fetchData = async () => {
      try {
        const response = await API.get(`/notises?limit=20&offset=${offset}`);
        const { results } = response.data;
        if (isApiSubscribed) {
          if (results.length >= 10) {
            setIsLoadMoreShown(true);
          } else {
            setIsLoadMoreShown(false);
          }
          setFeedList(feedList => [...feedList, ...results]);
        }
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setIsLoading(false);
        setIsPending(false);
        setIsPaginating(false);
      }
    }

    const timeout = setTimeout(() => {
      void fetchData();
    }, 500)

    return () => {
      clearTimeout(timeout);
      isApiSubscribed = false;
    }
  }, [offset, setIsPending, setIsPaginating]);

  if (error) {
    return <h1>{CONST_FETCH_PAGE_ERROR}</h1>
  }

  return (
    <>
      <PageHeader mb={72} title={'Activity Feed'} />

      {isLoading
        ? <SkeletonFeed items={3} />
        : feedList && feedList.length
          ? <FeedList
            feedList={feedList}
            setFeedList={setFeedList} />
          : <h2>Feed list is empty</h2>
      }

      {isLoading ? null : isLoadMoreShown &&
          <LoadMore setOffset={setOffset} />
      }
    </>
  );
}
