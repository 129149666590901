import React, { SetStateAction, useRef } from 'react';
import { UploadIcon } from 'assets/icons';
import { ButtonUpload } from 'components';
import { useUsers } from 'context';
import { API } from 'api';

interface FileUploadProps {
  setCleanup?: React.Dispatch<SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

export function FileUpload({ setCleanup, setIsLoading }: FileUploadProps) {
  const { query, setQuery, setIsUploading } = useUsers();
  const fileRef = useRef<HTMLInputElement>(null);

  const uploadListHandler = async () => {
    if (fileRef.current && fileRef.current.files && fileRef.current!.files![0]) {
      const data = new FormData();
      data.append('source', fileRef.current.files[0]);
      setIsUploading(true);

      try {
        await API.post('/members/import', data);
        setTimeout(() => {
          setCleanup!(true);
          setIsLoading(true);
          setQuery(!query);
        }, 500)
        alert(`File has been successfully uploaded!`);
      } catch (e: any) {
        console.error(e);
        alert(`Failed to upload: ${e.response?.data?.message}`);
      } finally {
        setIsUploading(false);
      }
    }
  }

  const cancelHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLButtonElement;
    target.value = '';
  }

  return (
    <ButtonUpload size={'lg'}>
      <input
        ref={fileRef}
        onClick={cancelHandler}
        // accept=".csv, text/csv"
        onChange={uploadListHandler}
        type="file" id="upload-list" />
      <UploadIcon />Upload list</ButtonUpload>
  );
}
