import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  TextMessage,
  PageHeader,
  DataGrid,
  SkeletonTable,
  Breadcrumbs,
  Skeleton,
  LoadMore,
  PageControls,
  Button
} from 'components';
import { useUsers } from 'context/UsersContext';
import { MembersTable } from './components';
import { getLastUrlSegment } from 'utils/helpers';
import { CategoryIcon } from 'assets/icons/category';
import { CONST_FETCH_PAGE_ERROR, CONST_USER_FETCH_TIMEOUT } from 'utils/constants';
import { API } from 'api';

interface ObjectLiteral {
  [key: string | number]: string
}

const currentCategory: ObjectLiteral = {
  'all': 'all',
  'students': 'student',
  'parents': 'parent',
}

export function Members() {
  const { users, query, setUsers, setIsPending, setIsPaginating } = useUsers();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isTitleLoading, setIsTitleLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isLoadMoreShown, setIsLoadMoreShown] = useState(false);
  const [cleanup, setCleanup] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageName, setPageName] = useState('');
  const parentCategory = pathname.split('/')[2];
  const category = getLastUrlSegment(pathname);
  const categoryToFetch = currentCategory[category!] !== 'all'
    ? `filters[roles][]=${currentCategory[category!]}` : '';

  const categories = [
    { path: `clubs-and-classes/${parentCategory}/${id}/all`, name: 'All' },
    { path: `clubs-and-classes/${parentCategory}/${id}/students`, name: 'Students' },
    { path: `clubs-and-classes/${parentCategory}/${id}/parents`, name: 'Parents' }
  ];

  const URL = `/${parentCategory}/${id}?limit=10&offset=${offset}&${categoryToFetch}`;
  const backURL = `clubs-and-classes/${parentCategory}`;

  useEffect(() => {
    let isApiSubscribed = true;
    const fetchData = async () => {
      try {
        const response = await API.get(URL);
        const { results } = response.data.members;
        if (results.length >= 10) {
          setIsLoadMoreShown(true);
        } else {
          setIsLoadMoreShown(false);
        }
        setUsers(users => [...users, ...results]);
        setPageName(name => response.data.name);
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setIsLoading(false);
        setIsTitleLoading(false);
        setIsPending(false)
        setIsPaginating(false)
      }
    }

    const timeout = setTimeout(() => {
      if (isApiSubscribed) {
        void fetchData();
      }
    }, CONST_USER_FETCH_TIMEOUT);

    return () => {
      clearTimeout(timeout);
      isApiSubscribed = false;
    };
  }, [URL, query, setUsers, setIsPending, setIsPaginating]);

  useEffect(() => {
    return () => {
      setUsers(users => [...[]]);
      setOffset(offset => 0);
      setCleanup(false);
    }
  }, [pathname, cleanup, setUsers, setIsPaginating]);

  if (users === undefined || error) {
    return (
      <PageHeader title={CONST_FETCH_PAGE_ERROR} />
    )
  }

  const onBackToHandler = () => {
    navigate(`/${backURL}`);
  }

  return (
    <>
      <Breadcrumbs url={backURL}
                   titleChildren={isTitleLoading ?
                     <Skeleton width={150} height={24} /> : pageName}>
        <CategoryIcon />
      </Breadcrumbs>

      <PageHeader mb={10} titleChildren={isTitleLoading ?
        <Skeleton width={400} height={24} /> : pageName} />
      <PageControls>
        <Button onClick={onBackToHandler} secondary size={'lg'}>Back</Button>
      </PageControls>

      <DataGrid
        searchUrl={`/${parentCategory}/${id}${categoryToFetch.length ? `?${categoryToFetch}&` : '?'}`}
        searchType={'members'}
        setLoading={setIsLoading}
        setIsLoadMoreShown={setIsLoadMoreShown}
        categories={categories}
        setSearchData={setUsers}>

        {isLoading ? <SkeletonTable rows={10} columns={4} /> :
          users.length ? <MembersTable /> : <TextMessage message={'No data'} />}
      </DataGrid>

      {isLoading ? null : isLoadMoreShown &&
          <LoadMore setOffset={setOffset} />
      }
    </>
  );
}
